/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Allergy,
    AllergyFromJSON,
    AllergyFromJSONTyped,
    AllergyToJSON,
} from './Allergy';
import {
    ClassSessionEnrollment,
    ClassSessionEnrollmentFromJSON,
    ClassSessionEnrollmentFromJSONTyped,
    ClassSessionEnrollmentToJSON,
} from './ClassSessionEnrollment';
import {
    ClassSessionWaitlistSubmission,
    ClassSessionWaitlistSubmissionFromJSON,
    ClassSessionWaitlistSubmissionFromJSONTyped,
    ClassSessionWaitlistSubmissionToJSON,
} from './ClassSessionWaitlistSubmission';
import {
    TrusteeStudentMapping,
    TrusteeStudentMappingFromJSON,
    TrusteeStudentMappingFromJSONTyped,
    TrusteeStudentMappingToJSON,
} from './TrusteeStudentMapping';
import {
    UserStudentMapping,
    UserStudentMappingFromJSON,
    UserStudentMappingFromJSONTyped,
    UserStudentMappingToJSON,
} from './UserStudentMapping';

/**
 * 
 * @export
 * @interface Student
 */
export interface Student {
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    readonly fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    country?: string | null;
    /**
     * 
     * @type {Array<Allergy>}
     * @memberof Student
     */
    allergies?: Array<Allergy> | null;
    /**
     * 
     * @type {Array<ClassSessionEnrollment>}
     * @memberof Student
     */
    classSessionEnrollments?: Array<ClassSessionEnrollment> | null;
    /**
     * 
     * @type {Array<ClassSessionWaitlistSubmission>}
     * @memberof Student
     */
    classSessionWaitlistSubmissions?: Array<ClassSessionWaitlistSubmission> | null;
    /**
     * 
     * @type {Array<UserStudentMapping>}
     * @memberof Student
     */
    userStudentMappings?: Array<UserStudentMapping> | null;
    /**
     * 
     * @type {Array<TrusteeStudentMapping>}
     * @memberof Student
     */
    trusteeStudentMappings?: Array<TrusteeStudentMapping> | null;
}

export function StudentFromJSON(json: any): Student {
    return StudentFromJSONTyped(json, false);
}

export function StudentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Student {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'allergies': !exists(json, 'allergies') ? undefined : (json['allergies'] === null ? null : (json['allergies'] as Array<any>).map(AllergyFromJSON)),
        'classSessionEnrollments': !exists(json, 'classSessionEnrollments') ? undefined : (json['classSessionEnrollments'] === null ? null : (json['classSessionEnrollments'] as Array<any>).map(ClassSessionEnrollmentFromJSON)),
        'classSessionWaitlistSubmissions': !exists(json, 'classSessionWaitlistSubmissions') ? undefined : (json['classSessionWaitlistSubmissions'] === null ? null : (json['classSessionWaitlistSubmissions'] as Array<any>).map(ClassSessionWaitlistSubmissionFromJSON)),
        'userStudentMappings': !exists(json, 'userStudentMappings') ? undefined : (json['userStudentMappings'] === null ? null : (json['userStudentMappings'] as Array<any>).map(UserStudentMappingFromJSON)),
        'trusteeStudentMappings': !exists(json, 'trusteeStudentMappings') ? undefined : (json['trusteeStudentMappings'] === null ? null : (json['trusteeStudentMappings'] as Array<any>).map(TrusteeStudentMappingFromJSON)),
    };
}

export function StudentToJSON(value?: Student | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dateOfBirth': value.dateOfBirth,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'zipCode': value.zipCode,
        'state': value.state,
        'country': value.country,
        'allergies': value.allergies === undefined ? undefined : (value.allergies === null ? null : (value.allergies as Array<any>).map(AllergyToJSON)),
        'classSessionEnrollments': value.classSessionEnrollments === undefined ? undefined : (value.classSessionEnrollments === null ? null : (value.classSessionEnrollments as Array<any>).map(ClassSessionEnrollmentToJSON)),
        'classSessionWaitlistSubmissions': value.classSessionWaitlistSubmissions === undefined ? undefined : (value.classSessionWaitlistSubmissions === null ? null : (value.classSessionWaitlistSubmissions as Array<any>).map(ClassSessionWaitlistSubmissionToJSON)),
        'userStudentMappings': value.userStudentMappings === undefined ? undefined : (value.userStudentMappings === null ? null : (value.userStudentMappings as Array<any>).map(UserStudentMappingToJSON)),
        'trusteeStudentMappings': value.trusteeStudentMappings === undefined ? undefined : (value.trusteeStudentMappings === null ? null : (value.trusteeStudentMappings as Array<any>).map(TrusteeStudentMappingToJSON)),
    };
}

