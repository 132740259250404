/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSessionEnrollmentDto,
    ClassSessionEnrollmentDtoFromJSON,
    ClassSessionEnrollmentDtoFromJSONTyped,
    ClassSessionEnrollmentDtoToJSON,
} from './ClassSessionEnrollmentDto';
import {
    DigitalPassportDto,
    DigitalPassportDtoFromJSON,
    DigitalPassportDtoFromJSONTyped,
    DigitalPassportDtoToJSON,
} from './DigitalPassportDto';
import {
    TrusteeDto,
    TrusteeDtoFromJSON,
    TrusteeDtoFromJSONTyped,
    TrusteeDtoToJSON,
} from './TrusteeDto';

/**
 * 
 * @export
 * @interface AttendanceSelfServiceDto
 */
export interface AttendanceSelfServiceDto {
    /**
     * 
     * @type {TrusteeDto}
     * @memberof AttendanceSelfServiceDto
     */
    trustee?: TrusteeDto;
    /**
     * 
     * @type {DigitalPassportDto}
     * @memberof AttendanceSelfServiceDto
     */
    digitalPassportDto?: DigitalPassportDto;
    /**
     * 
     * @type {Array<ClassSessionEnrollmentDto>}
     * @memberof AttendanceSelfServiceDto
     */
    classSessionEnrollments?: Array<ClassSessionEnrollmentDto> | null;
}

export function AttendanceSelfServiceDtoFromJSON(json: any): AttendanceSelfServiceDto {
    return AttendanceSelfServiceDtoFromJSONTyped(json, false);
}

export function AttendanceSelfServiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendanceSelfServiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trustee': !exists(json, 'trustee') ? undefined : TrusteeDtoFromJSON(json['trustee']),
        'digitalPassportDto': !exists(json, 'digitalPassportDto') ? undefined : DigitalPassportDtoFromJSON(json['digitalPassportDto']),
        'classSessionEnrollments': !exists(json, 'classSessionEnrollments') ? undefined : (json['classSessionEnrollments'] === null ? null : (json['classSessionEnrollments'] as Array<any>).map(ClassSessionEnrollmentDtoFromJSON)),
    };
}

export function AttendanceSelfServiceDtoToJSON(value?: AttendanceSelfServiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trustee': TrusteeDtoToJSON(value.trustee),
        'digitalPassportDto': DigitalPassportDtoToJSON(value.digitalPassportDto),
        'classSessionEnrollments': value.classSessionEnrollments === undefined ? undefined : (value.classSessionEnrollments === null ? null : (value.classSessionEnrollments as Array<any>).map(ClassSessionEnrollmentDtoToJSON)),
    };
}

