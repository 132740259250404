import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Container from "Components/Container";
import { Divider } from "@mui/material";
import { Link as Link2 } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha, useTheme } from "@mui/material/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HeroFullWidth = (props) => {
  const {
    title,
    subtitle,
    imgbleed,
    imgpos,
    imgsrc,
    fontcolor = "white",
    bgcolor,
    buttontext,
    buttonvariant,
  } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      bgcolor={bgcolor}
      display="flex"
      alignItems="center"
      minHeight={{ xs: 300, sm: 1, md: 250, lg: 420 }}
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto 100%",
        backgroundPosition: `${imgpos} center`,
        /*backgroundImage: `url(${imgsrc})`,*/
        backgroundImage: {
          xs: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imgsrc})`, // Darker on mobile
          sm: `url(${imgsrc})`, // Original image on larger screens
        },
      }}
    >
      <Container maxWidth={1600}>
        <Box
          maxWidth={{ xs: 1, sm: "35%" }}
          sx={{
            ml: imgpos === "left" ? "auto" : "unset",
          }}
        >
          <Typography
            variant="h2"
            color={fontcolor}
            gutterBottom
            sx={{
              fontWeight: 700,
              textAlign: imgpos === "left" ? "right" : "left",
              color: {
                xs: "white",
                sm: `${fontcolor}`,
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              fontWeight: 400,
              textAlign: imgpos === "left" ? "right" : "left",
              color: {
                xs: "white",
                sm: `${fontcolor}`,
              },
            }}
          >
            {subtitle}
          </Typography>
          {buttontext && (
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "flex-start" }}
              marginTop={4}
            >
              <Button
                component={Link2}
                variant={buttonvariant}
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                to={"/our-philosophy"}
                sx={{ color: "black" }}
                endIcon={
                  <Box
                    component={"svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 31 31"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </Box>
                }
              >
                {buttontext}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default HeroFullWidth;
