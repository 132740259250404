/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoFromJSONTyped,
    StudentDtoToJSON,
} from './StudentDto';
import {
    TrusteeDto,
    TrusteeDtoFromJSON,
    TrusteeDtoFromJSONTyped,
    TrusteeDtoToJSON,
} from './TrusteeDto';

/**
 * 
 * @export
 * @interface TrusteeStudentMappingDto
 */
export interface TrusteeStudentMappingDto {
    /**
     * 
     * @type {string}
     * @memberof TrusteeStudentMappingDto
     */
    id?: string | null;
    /**
     * 
     * @type {TrusteeDto}
     * @memberof TrusteeStudentMappingDto
     */
    trustee?: TrusteeDto;
    /**
     * 
     * @type {StudentDto}
     * @memberof TrusteeStudentMappingDto
     */
    student?: StudentDto;
}

export function TrusteeStudentMappingDtoFromJSON(json: any): TrusteeStudentMappingDto {
    return TrusteeStudentMappingDtoFromJSONTyped(json, false);
}

export function TrusteeStudentMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrusteeStudentMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'trustee': !exists(json, 'trustee') ? undefined : TrusteeDtoFromJSON(json['trustee']),
        'student': !exists(json, 'student') ? undefined : StudentDtoFromJSON(json['student']),
    };
}

export function TrusteeStudentMappingDtoToJSON(value?: TrusteeStudentMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'trustee': TrusteeDtoToJSON(value.trustee),
        'student': StudentDtoToJSON(value.student),
    };
}

