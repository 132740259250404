import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Link as Link2 } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const programs = [
  {
    name: "Sprouts",
    image: "/static/images/sprouts.png",
    description: (
      <>
        Morning learning programs for children ages 3½-5.
        <br />
        Space is limited, enrollment open now.
      </>
    ),
    button: (
      <Button component={Link2} to={"/sessions"} variant="green">
        Enroll Now
      </Button>
    ),
  },
  {
    name: "Blossoms",
    image: "/static/images/blossoms.png",
    description: (
      <>
        1-on-1 and small group tutoring for grades K-6.
        <br />
        Coming Fall 2024. Join our waitlist.
      </>
    ),
    button: (
      <Button component={Link2} to={"/sessions"} variant="yellow">
        Join the Waitlist
      </Button>
    ),
  },
];

const OurPrograms = () => {
  return (
    <Box textAlign="center" sx={{ mb: { xs: 1, md: 1 } }}>
      <Typography
        variant="h3"
        align="center"
        sx={{ fontWeight: 700, mb: { xs: 5, sm: 10 } }}
      >
        Our Programs
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {programs.map((program, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box sx={{ marginBottom: { xs: 5 } }}>
              <Box
                justifyContent="center"
                component={LazyLoadImage}
                effect="blur"
                src={program.image}
                alt={program.name}
                height={100}
                sx={{ mb: { xs: 1, sm: 4 } }}
              ></Box>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{
                  fontWeight: 700,
                }}
              >
                {program.name}
              </Typography>
              <Box>
                <Typography variant="body" align="center">
                  {program.description}
                </Typography>
              </Box>
              <Box sx={{ marginTop: { xs: 1, sm: 3 } }}>{program.button}</Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurPrograms;
