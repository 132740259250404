/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSession,
    ClassSessionFromJSON,
    ClassSessionFromJSONTyped,
    ClassSessionToJSON,
} from './ClassSession';

/**
 * 
 * @export
 * @interface DigitalResource
 */
export interface DigitalResource {
    /**
     * 
     * @type {string}
     * @memberof DigitalResource
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DigitalResource
     */
    type?: number;
    /**
     * 
     * @type {string}
     * @memberof DigitalResource
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalResource
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalResource
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalResource
     */
    url?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DigitalResource
     */
    isActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DigitalResource
     */
    uploadDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DigitalResource
     */
    classSessionId?: string | null;
    /**
     * 
     * @type {ClassSession}
     * @memberof DigitalResource
     */
    classSession?: ClassSession;
}

export function DigitalResourceFromJSON(json: any): DigitalResource {
    return DigitalResourceFromJSONTyped(json, false);
}

export function DigitalResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigitalResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (new Date(json['uploadDate'])),
        'classSessionId': !exists(json, 'classSessionId') ? undefined : json['classSessionId'],
        'classSession': !exists(json, 'classSession') ? undefined : ClassSessionFromJSON(json['classSession']),
    };
}

export function DigitalResourceToJSON(value?: DigitalResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'name': value.name,
        'fileName': value.fileName,
        'description': value.description,
        'url': value.url,
        'isActive': value.isActive,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate.toISOString()),
        'classSessionId': value.classSessionId,
        'classSession': ClassSessionToJSON(value.classSession),
    };
}

