import React from "react";
import "./App.css";
import { RequireAuth } from "./Utilities/Auth";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Main } from "./layouts";

import IndexView from "./views/IndexView";
import SigninCover from "./views/SigninCover";
import SignupCover from "./views/SignupCover";
import Logout from "./Components/Authentication/Logout";
import PasswordResetCover from "views/PasswordResetCover";
import ForgotPasswordCover from "views/ForgotPasswordCover";
import TermsAndConditions from "views/TermsAndConditions";
import ScrollToTop from "Utilities/ScrollToTop";
import NotFoundCover from "views/NotFoundCover";

function App() {
  const DashboardView = React.lazy(() => import("views/DashboardView"));
  const ContactUs = React.lazy(() => import("./views/ContactUs"));
  const FAQ = React.lazy(() => import("./views/FAQ"));
  const ClassSessionOverview = React.lazy(() =>
    import("./views/ClassSessionOverview")
  );
  const SessionsView = React.lazy(() => import("./views/SessionsView"));
  const OurPhilosophy = React.lazy(() => import("./views/OurPhilosophy"));
  const Testimonials = React.lazy(() => import("./views/Testimonials"));
  const SessionSignup = React.lazy(() =>
    import("./Components/Sessions/SessionSignup")
  );
  const SessionWaitlist = React.lazy(() =>
    import("./Components/Sessions/SessionWaitlist")
  );
  const SessionWaitlistConfirmation = React.lazy(() =>
    import("./Components/Sessions/SessionWaitlistConfirmation")
  );
  const SessionEnrollmentSuccessful = React.lazy(() =>
    import("views/SessionEnrollmentSuccessful")
  );
  const SessionEnrollmentFailed = React.lazy(() =>
    import("views/SessionEnrollmentFailed")
  );
  const MyStudents = React.lazy(() =>
    import("./Components/Dashboard/MyStudents")
  );
  const NewStudent = React.lazy(() =>
    import("./Components/Dashboard/NewStudent")
  );
  const Billing = React.lazy(() => import("./Components/Dashboard/Billing"));
  const NewAllergy = React.lazy(() =>
    import("./Components/Dashboard/NewAllergy")
  );
  const ClassSessionDetails = React.lazy(() =>
    import("./Components/Dashboard/ClassSessionDetails")
  );
  const ClassSessionEnrollments = React.lazy(() =>
    import("./Components/Dashboard/ClassSessionEnrollments")
  );
  const DashboardHome = React.lazy(() =>
    import("./Components/Dashboard/DashboardHome")
  );
  const NewTrustee = React.lazy(() =>
    import("./Components/Dashboard/NewTrustee")
  );
  const UpdateTrustee = React.lazy(() =>
    import("./Components/Dashboard/UpdateTrustee")
  );
  const EmergencyContacts = React.lazy(() =>
    import("Components/Dashboard/EmergencyContacts")
  );

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/login" exact element={<SigninCover />} />
        <Route path="/register" exact element={<SignupCover />} />
        <Route path="/password-reset" exact element={<ForgotPasswordCover />} />
        <Route
          path="/password-reset/:token"
          exact
          element={<PasswordResetCover />}
        />
        <Route path="/" element={<Main />}>
          <Route path="/" exact element={<IndexView />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="logout" element={<Logout />} />
          <Route
            path="our-philosophy"
            element={
              <React.Suspense fallback={<>...</>}>
                <OurPhilosophy />
              </React.Suspense>
            }
          />
          <Route
            path="sessions"
            element={
              <React.Suspense fallback={<>...</>}>
                <SessionsView />
              </React.Suspense>
            }
          />
          <Route
            path="testimonials"
            element={
              <React.Suspense fallback={<>...</>}>
                <Testimonials />
              </React.Suspense>
            }
          />
          <Route
            path="sessions/:classSessionId"
            element={
              <React.Suspense fallback={<>...</>}>
                <ClassSessionOverview />
              </React.Suspense>
            }
          />
          <Route
            path="frequently-asked-questions"
            element={
              <React.Suspense fallback={<>...</>}>
                <FAQ />
              </React.Suspense>
            }
          />
          <Route
            path="faq"
            element={
              <React.Suspense fallback={<>...</>}>
                <FAQ />
              </React.Suspense>
            }
          />
          <Route
            path="contact"
            element={
              <React.Suspense fallback={<>...</>}>
                <ContactUs />
              </React.Suspense>
            }
          />
          <Route
            path="contact-us"
            element={
              <React.Suspense fallback={<>...</>}>
                <ContactUs />
              </React.Suspense>
            }
          />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <React.Suspense fallback={<>...</>}>
                  <DashboardView />
                </React.Suspense>
              </RequireAuth>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<>...</>}>
                  <DashboardHome />
                </React.Suspense>
              }
            />
            <Route
              path="billing"
              element={
                <React.Suspense fallback={<>...</>}>
                  <Billing />
                </React.Suspense>
              }
            />
            <Route
              path="sessions"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ClassSessionEnrollments />
                </React.Suspense>
              }
            />
            <Route
              path="sessions/:classSessionId"
              element={
                <React.Suspense fallback={<>...</>}>
                  <ClassSessionDetails />
                </React.Suspense>
              }
            />
            <Route
              path="my-students"
              element={
                <React.Suspense fallback={<>...</>}>
                  <MyStudents />
                </React.Suspense>
              }
            />
            <Route
              path="emergency-contacts"
              element={
                <React.Suspense fallback={<>...</>}>
                  <EmergencyContacts />
                </React.Suspense>
              }
            />
            <Route
              path="my-students/new"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NewStudent />
                </React.Suspense>
              }
            />
            <Route
              path="emergency-contacts/new"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NewTrustee />
                </React.Suspense>
              }
            />
            <Route
              path="emergency-contacts/:trusteeId"
              element={
                <React.Suspense fallback={<>...</>}>
                  <UpdateTrustee />
                </React.Suspense>
              }
            />
            <Route
              path="my-students/:studentId/allergies/new"
              element={
                <React.Suspense fallback={<>...</>}>
                  <NewAllergy />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path="sessions/:classSessionId/enroll"
            element={
              <RequireAuth>
                <React.Suspense fallback={<>...</>}>
                  <SessionSignup />
                </React.Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="sessions/:classSessionId/waitlist"
            element={
              <RequireAuth>
                <React.Suspense fallback={<>...</>}>
                  <SessionWaitlist />
                </React.Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="sessions/:classSessionId/waitlist/success"
            element={
              <RequireAuth>
                <React.Suspense fallback={<>...</>}>
                  <SessionWaitlistConfirmation />
                </React.Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="sessions/:classSessionId/enrollment-successful/:checkoutId"
            element={
              <React.Suspense fallback={<>...</>}>
                <SessionEnrollmentSuccessful />
              </React.Suspense>
            }
          />
          <Route
            path="sessions/:classSessionId/enrollment-failed/:checkoutId"
            element={
              <React.Suspense fallback={<>...</>}>
                <SessionEnrollmentFailed />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="*" element={<NotFoundCover />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
