/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";

const mock = [
  {
    title: "Our Philosophy",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ultrices lectus at sollicitudin aliquam. Pellentesque egestas metus luctus, consectetur libero ac, bibendum purus.",
  },
  {
    title: "Testimonials",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ultrices lectus at sollicitudin aliquam. Pellentesque egestas metus luctus, consectetur libero ac, bibendum purus.",
  },
  {
    title: "Contact",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ultrices lectus at sollicitudin aliquam. Pellentesque egestas metus luctus, consectetur libero ac, bibendum purus.",
  },
];

const PagesOverview = () => {
  const theme = useTheme();
  return (
    <Box align="center">
      <Box
        sx={{
          marginBottom: { xs: 1, md: 1 },
          marginTop: { xs: 1, md: 5 },
          maxWidth: { xs: "100%", md: "80%" },
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontWeight: 400,
            lineHeight: { xs: "55px", md: "55px" },
          }}
        >
          Bloom is a modern learning center for children ages 3½-12 where
          learning is{" "}
          <Box
            sx={{
              background: `linear-gradient(to top, ${theme.palette.background.yellow} 40%, transparent 40%)`,
            }}
            display="inline"
          >
            fun
          </Box>
          . We offer a hands-on approach to education because children excel
          when they are active participants.
        </Typography>
      </Box>
      {/*<Grid container spacing={5}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box width={1} height={1}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Avatar
                  src={item.avatar}
                  variant={"rounded"}
                  sx={{
                    width: 200,
                    height: 200,
                    borderRadius: 2,
                    marginBottom: 2,
                  }}
                />
                <Typography
                  variant={"h6"}
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                  align={"center"}
                >
                  {item.title}
                </Typography>
                <Typography align={"center"} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>*/}
    </Box>
  );
};

export default PagesOverview;
