import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { Link as Link2 } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SocialMedia = () => {
  return (
    <Grid item xs={12} align="center">
      <Grid
        container
        spacing={{ xs: 5, sm: 1 }}
        alignContent="space-evenly"
        justifyContent="space-evenly"
        sx={{ marginTop: { xs: 5, sm: 1 } }}
      >
        <Grid item xs={6} sm={2} align="center">
          <Box
            component={Link2}
            to={"/contact-us?tour=true"}
            sx={{
              textDecoration: "none",
              "&:visited": { color: "#000" },
            }}
          >
            <Box
              justifyContent="center"
              component={LazyLoadImage}
              effect="blur"
              src={"/static/images/faq/FAQ_ScheduleTour.png"}
              alt={"Schedule a Tour"}
              height={70}
              sx={{ mb: { xs: 1, sm: 4 } }}
            ></Box>
            <br />
            <Typography variant="body" align="center" sx={{ fontWeight: 600 }}>
              Schedule a Tour
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box
            component="a"
            href={"https://www.yelp.com/biz/bloom-chino-hills"}
            target={"_blank"}
            sx={{
              textDecoration: "none",
              "&:visited": { color: "#000" },
            }}
          >
            <Box
              justifyContent="center"
              component={LazyLoadImage}
              effect="blur"
              src={"/static/images/faq/FAQ_Yelp.png"}
              alt={"Yelp"}
              height={70}
              sx={{ mb: { xs: 1, sm: 4 } }}
            ></Box>
            <br />
            <Typography variant="body" align="center" sx={{ fontWeight: 600 }}>
              Yelp
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box
            component="a"
            href={
              "https://winnie.com/place/fb4306b8-78df-4998-aa5e-23d096d4b20e"
            }
            target={"_blank"}
            sx={{
              textDecoration: "none",
              "&:visited": { color: "#000" },
            }}
          >
            <Box
              justifyContent="center"
              component={LazyLoadImage}
              effect="blur"
              src={"/static/images/faq/FAQ_Winnie.png"}
              alt={"Winnie"}
              height={35}
              sx={{ mb: { xs: 2, sm: 7 }, mt: { xs: 2, sm: 2 } }}
            ></Box>
            <br />
            <Typography variant="body" align="center" sx={{ fontWeight: 600 }}>
              Winnie
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box
            component="a"
            href={"https://instagram.com/bloomlearngrow"}
            target={"_blank"}
            sx={{
              textDecoration: "none",
              "&:visited": { color: "#000" },
            }}
          >
            <Box
              justifyContent="center"
              component={LazyLoadImage}
              effect="blur"
              src={"/static/images/faq/FAQ_IG.png"}
              alt={"Instagram"}
              height={{ xs: 60, sm: 70 }}
              sx={{ mb: { xs: 1, sm: 4 } }}
            ></Box>
            <br />
            <Typography variant="body" align="center" sx={{ fontWeight: 600 }}>
              @bloomlearngrow
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Box
            component="a"
            href={"https://www.facebook.com/bloomlearngrow/"}
            target={"_blank"}
            sx={{
              textDecoration: "none",
              "&:visited": { color: "#000" },
            }}
          >
            <Box
              justifyContent="center"
              component={LazyLoadImage}
              effect="blur"
              src={"/static/images/faq/FAQ_FB.png"}
              alt={"Facebook"}
              height={{ xs: 60, sm: 70 }}
              sx={{ mb: { xs: 1, sm: 4 } }}
            ></Box>
            <br />
            <Typography variant="body" align="center" sx={{ fontWeight: 600 }}>
              @bloomlearngrow
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SocialMedia;
