/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AllergyDto,
    AllergyDtoFromJSON,
    AllergyDtoFromJSONTyped,
    AllergyDtoToJSON,
} from './AllergyDto';
import {
    ClassSessionEnrollmentDto,
    ClassSessionEnrollmentDtoFromJSON,
    ClassSessionEnrollmentDtoFromJSONTyped,
    ClassSessionEnrollmentDtoToJSON,
} from './ClassSessionEnrollmentDto';
import {
    ClassSessionWaitlistSubmissionDto,
    ClassSessionWaitlistSubmissionDtoFromJSON,
    ClassSessionWaitlistSubmissionDtoFromJSONTyped,
    ClassSessionWaitlistSubmissionDtoToJSON,
} from './ClassSessionWaitlistSubmissionDto';
import {
    TrusteeStudentMappingDto,
    TrusteeStudentMappingDtoFromJSON,
    TrusteeStudentMappingDtoFromJSONTyped,
    TrusteeStudentMappingDtoToJSON,
} from './TrusteeStudentMappingDto';
import {
    UserStudentMappingDto,
    UserStudentMappingDtoFromJSON,
    UserStudentMappingDtoFromJSONTyped,
    UserStudentMappingDtoToJSON,
} from './UserStudentMappingDto';

/**
 * 
 * @export
 * @interface StudentDto
 */
export interface StudentDto {
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    dateOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StudentDto
     */
    country?: string | null;
    /**
     * 
     * @type {Array<TrusteeStudentMappingDto>}
     * @memberof StudentDto
     */
    trusteeStudentMappings?: Array<TrusteeStudentMappingDto> | null;
    /**
     * 
     * @type {Array<AllergyDto>}
     * @memberof StudentDto
     */
    allergies?: Array<AllergyDto> | null;
    /**
     * 
     * @type {Array<ClassSessionEnrollmentDto>}
     * @memberof StudentDto
     */
    classSessionEnrollments?: Array<ClassSessionEnrollmentDto> | null;
    /**
     * 
     * @type {Array<ClassSessionWaitlistSubmissionDto>}
     * @memberof StudentDto
     */
    classSessionWaitlistSubmissions?: Array<ClassSessionWaitlistSubmissionDto> | null;
    /**
     * 
     * @type {Array<UserStudentMappingDto>}
     * @memberof StudentDto
     */
    userStudentMappings?: Array<UserStudentMappingDto> | null;
}

export function StudentDtoFromJSON(json: any): StudentDto {
    return StudentDtoFromJSONTyped(json, false);
}

export function StudentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : json['dateOfBirth'],
        'address1': !exists(json, 'address1') ? undefined : json['address1'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'trusteeStudentMappings': !exists(json, 'trusteeStudentMappings') ? undefined : (json['trusteeStudentMappings'] === null ? null : (json['trusteeStudentMappings'] as Array<any>).map(TrusteeStudentMappingDtoFromJSON)),
        'allergies': !exists(json, 'allergies') ? undefined : (json['allergies'] === null ? null : (json['allergies'] as Array<any>).map(AllergyDtoFromJSON)),
        'classSessionEnrollments': !exists(json, 'classSessionEnrollments') ? undefined : (json['classSessionEnrollments'] === null ? null : (json['classSessionEnrollments'] as Array<any>).map(ClassSessionEnrollmentDtoFromJSON)),
        'classSessionWaitlistSubmissions': !exists(json, 'classSessionWaitlistSubmissions') ? undefined : (json['classSessionWaitlistSubmissions'] === null ? null : (json['classSessionWaitlistSubmissions'] as Array<any>).map(ClassSessionWaitlistSubmissionDtoFromJSON)),
        'userStudentMappings': !exists(json, 'userStudentMappings') ? undefined : (json['userStudentMappings'] === null ? null : (json['userStudentMappings'] as Array<any>).map(UserStudentMappingDtoFromJSON)),
    };
}

export function StudentDtoToJSON(value?: StudentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dateOfBirth': value.dateOfBirth,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'zipCode': value.zipCode,
        'state': value.state,
        'country': value.country,
        'trusteeStudentMappings': value.trusteeStudentMappings === undefined ? undefined : (value.trusteeStudentMappings === null ? null : (value.trusteeStudentMappings as Array<any>).map(TrusteeStudentMappingDtoToJSON)),
        'allergies': value.allergies === undefined ? undefined : (value.allergies === null ? null : (value.allergies as Array<any>).map(AllergyDtoToJSON)),
        'classSessionEnrollments': value.classSessionEnrollments === undefined ? undefined : (value.classSessionEnrollments === null ? null : (value.classSessionEnrollments as Array<any>).map(ClassSessionEnrollmentDtoToJSON)),
        'classSessionWaitlistSubmissions': value.classSessionWaitlistSubmissions === undefined ? undefined : (value.classSessionWaitlistSubmissions === null ? null : (value.classSessionWaitlistSubmissions as Array<any>).map(ClassSessionWaitlistSubmissionDtoToJSON)),
        'userStudentMappings': value.userStudentMappings === undefined ? undefined : (value.userStudentMappings === null ? null : (value.userStudentMappings as Array<any>).map(UserStudentMappingDtoToJSON)),
    };
}

