/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSessionEnrollment,
    ClassSessionEnrollmentFromJSON,
    ClassSessionEnrollmentFromJSONTyped,
    ClassSessionEnrollmentToJSON,
} from './ClassSessionEnrollment';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    originalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    finalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    checkoutUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    checkoutId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paymentIntentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    checkoutJSON?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    chargeJSON?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    receiptUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof Payment
     */
    user?: User;
    /**
     * 
     * @type {Array<ClassSessionEnrollment>}
     * @memberof Payment
     */
    classSessionEnrollments?: Array<ClassSessionEnrollment> | null;
}

export function PaymentFromJSON(json: any): Payment {
    return PaymentFromJSONTyped(json, false);
}

export function PaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'originalAmount': !exists(json, 'originalAmount') ? undefined : json['originalAmount'],
        'finalAmount': !exists(json, 'finalAmount') ? undefined : json['finalAmount'],
        'checkoutUrl': !exists(json, 'checkoutUrl') ? undefined : json['checkoutUrl'],
        'checkoutId': !exists(json, 'checkoutId') ? undefined : json['checkoutId'],
        'paymentIntentId': !exists(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'checkoutJSON': !exists(json, 'checkoutJSON') ? undefined : json['checkoutJSON'],
        'chargeJSON': !exists(json, 'chargeJSON') ? undefined : json['chargeJSON'],
        'receiptUrl': !exists(json, 'receiptUrl') ? undefined : json['receiptUrl'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'classSessionEnrollments': !exists(json, 'classSessionEnrollments') ? undefined : (json['classSessionEnrollments'] === null ? null : (json['classSessionEnrollments'] as Array<any>).map(ClassSessionEnrollmentFromJSON)),
    };
}

export function PaymentToJSON(value?: Payment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'originalAmount': value.originalAmount,
        'finalAmount': value.finalAmount,
        'checkoutUrl': value.checkoutUrl,
        'checkoutId': value.checkoutId,
        'paymentIntentId': value.paymentIntentId,
        'checkoutJSON': value.checkoutJSON,
        'chargeJSON': value.chargeJSON,
        'receiptUrl': value.receiptUrl,
        'phoneNumber': value.phoneNumber,
        'emailAddress': value.emailAddress,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'classSessionEnrollments': value.classSessionEnrollments === undefined ? undefined : (value.classSessionEnrollments === null ? null : (value.classSessionEnrollments as Array<any>).map(ClassSessionEnrollmentToJSON)),
    };
}

