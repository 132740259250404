import React, { useContext } from "react";
import { AuthContext } from "../../Utilities/Auth";
import { Navigate } from "react-router-dom";

export default function Logout(props) {
  const [, , logout] = useContext(AuthContext);

  logout();

  return <Navigate to="/login" />;
}
