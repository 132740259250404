/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Grid, TextField, Button, Typography, Link } from "@mui/material";
import { UserAuthenticationApi, UserDtoFromJSON } from "API";
import { AuthContext } from "Utilities/Auth";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, Link as Link2 } from "react-router-dom";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  emailAddress: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

const Form = () => {
  const [apiConfig, login] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const userAuthenticationApi = new UserAuthenticationApi(apiConfig);
  const location = useLocation();
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  let redirect =
    new URLSearchParams(location.search).get("redirect") || "/dashboard";

  const initialValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
  };

  const onSubmit = (values) => {
    processRegistration(values);
  };

  const processRegistration = (values) => {
    setLoading(true);
    userAuthenticationApi
      .apiUserAuthenticationRegisterPost({
        userDto: new UserDtoFromJSON(values),
      })
      .then((result) => {
        enqueueSnackbar(
          "Your account has been created. Please check your email to confirm.",
          { variant: "success" }
        );
        login(result);
        navigate(redirect);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Failed to create your account. Please try again.", {
          variant: "error",
        });
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Signup
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Create an account
        </Typography>
        <Typography color="text.secondary">
          Fill out the form to get started.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant={"subtitle2"}
              sx={{ marginBottom: 2 }}
            ></Typography>
            <TextField
              label="First name *"
              variant="outlined"
              name={"firstName"}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant={"subtitle2"}
              sx={{ marginBottom: 2 }}
            ></Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={"lastName"}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={"subtitle2"}
              sx={{ marginBottom: 2 }}
            ></Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={"emailAddress"}
              fullWidth
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.emailAddress &&
                Boolean(formik.errors.emailAddress)
              }
              helperText={
                formik.touched.emailAddress && formik.errors.emailAddress
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={"subtitle2"}
              sx={{ marginBottom: 2 }}
            ></Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  Already have an account?{" "}
                  <Link
                    component={Link2}
                    color={"primary"}
                    to={"/login"}
                    underline={"none"}
                  >
                    Login.
                  </Link>
                </Typography>
              </Box>
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                disabled={loading}
              >
                Sign up
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"text.secondary"}
              align={"center"}
            >
              By clicking "Sign up" button you agree with our{" "}
              <Link
                component={Link2}
                color={"primary"}
                to={"/terms-and-conditions"}
                underline={"none"}
              >
                terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
