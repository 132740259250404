/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementDto,
    AnnouncementDtoFromJSON,
    AnnouncementDtoFromJSONTyped,
    AnnouncementDtoToJSON,
} from './AnnouncementDto';

/**
 * 
 * @export
 * @interface NewAnnouncementDto
 */
export interface NewAnnouncementDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof NewAnnouncementDto
     */
    classSessionIds?: Array<string> | null;
    /**
     * 
     * @type {AnnouncementDto}
     * @memberof NewAnnouncementDto
     */
    announcement?: AnnouncementDto;
}

export function NewAnnouncementDtoFromJSON(json: any): NewAnnouncementDto {
    return NewAnnouncementDtoFromJSONTyped(json, false);
}

export function NewAnnouncementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewAnnouncementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classSessionIds': !exists(json, 'classSessionIds') ? undefined : json['classSessionIds'],
        'announcement': !exists(json, 'announcement') ? undefined : AnnouncementDtoFromJSON(json['announcement']),
    };
}

export function NewAnnouncementDtoToJSON(value?: NewAnnouncementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classSessionIds': value.classSessionIds,
        'announcement': AnnouncementDtoToJSON(value.announcement),
    };
}

