/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactFormDto
 */
export interface ContactFormDto {
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    childName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    childBirthdate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    previousAttendance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    referred?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormDto
     */
    message?: string | null;
}

export function ContactFormDtoFromJSON(json: any): ContactFormDto {
    return ContactFormDtoFromJSONTyped(json, false);
}

export function ContactFormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactFormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'childName': !exists(json, 'childName') ? undefined : json['childName'],
        'childBirthdate': !exists(json, 'childBirthdate') ? undefined : json['childBirthdate'],
        'previousAttendance': !exists(json, 'previousAttendance') ? undefined : json['previousAttendance'],
        'referred': !exists(json, 'referred') ? undefined : json['referred'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ContactFormDtoToJSON(value?: ContactFormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'childName': value.childName,
        'childBirthdate': value.childBirthdate,
        'previousAttendance': value.previousAttendance,
        'referred': value.referred,
        'message': value.message,
    };
}

