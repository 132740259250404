/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSession,
    ClassSessionFromJSON,
    ClassSessionFromJSONTyped,
    ClassSessionToJSON,
} from './ClassSession';

/**
 * 
 * @export
 * @interface ClassSection
 */
export interface ClassSection {
    /**
     * 
     * @type {string}
     * @memberof ClassSection
     */
    id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassSection
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClassSection
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSection
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassSection
     */
    endDate?: Date;
    /**
     * 
     * @type {Array<ClassSession>}
     * @memberof ClassSection
     */
    classSessions?: Array<ClassSession> | null;
}

export function ClassSectionFromJSON(json: any): ClassSection {
    return ClassSectionFromJSONTyped(json, false);
}

export function ClassSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'classSessions': !exists(json, 'classSessions') ? undefined : (json['classSessions'] === null ? null : (json['classSessions'] as Array<any>).map(ClassSessionFromJSON)),
    };
}

export function ClassSectionToJSON(value?: ClassSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'name': value.name,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'classSessions': value.classSessions === undefined ? undefined : (value.classSessions === null ? null : (value.classSessions as Array<any>).map(ClassSessionToJSON)),
    };
}

