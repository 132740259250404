/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClassSessionDto,
    ClassSessionDtoFromJSON,
    ClassSessionDtoToJSON,
    DigitalResourceDto,
    DigitalResourceDtoFromJSON,
    DigitalResourceDtoToJSON,
} from '../models';

export interface ApiClassSessionClassSessionIdGetRequest {
    classSessionId: string;
}

export interface ApiClassSessionClassSessionIdPutRequest {
    classSessionId: string;
    classSessionDto?: ClassSessionDto;
}

export interface ApiClassSessionClassSessionIdResourcesGetRequest {
    classSessionId: string;
}

export interface ApiClassSessionGetRequest {
    active?: string;
}

export interface ApiClassSessionPostRequest {
    classSessionDto?: ClassSessionDto;
}

/**
 * 
 */
export class ClassSessionApi extends runtime.BaseAPI {

    /**
     */
    async apiClassSessionAllGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassSessionDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSession/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassSessionDtoFromJSON));
    }

    /**
     */
    async apiClassSessionAllGet(initOverrides?: RequestInit): Promise<Array<ClassSessionDto>> {
        const response = await this.apiClassSessionAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionClassSessionIdGetRaw(requestParameters: ApiClassSessionClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassSessionDto>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionClassSessionIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSession/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassSessionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClassSessionClassSessionIdGet(requestParameters: ApiClassSessionClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<ClassSessionDto> {
        const response = await this.apiClassSessionClassSessionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionClassSessionIdPutRaw(requestParameters: ApiClassSessionClassSessionIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionClassSessionIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ClassSession/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClassSessionDtoToJSON(requestParameters.classSessionDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiClassSessionClassSessionIdPut(requestParameters: ApiClassSessionClassSessionIdPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiClassSessionClassSessionIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionClassSessionIdResourcesGetRaw(requestParameters: ApiClassSessionClassSessionIdResourcesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DigitalResourceDto>>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionClassSessionIdResourcesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSession/{classSessionId}/Resources`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DigitalResourceDtoFromJSON));
    }

    /**
     */
    async apiClassSessionClassSessionIdResourcesGet(requestParameters: ApiClassSessionClassSessionIdResourcesGetRequest, initOverrides?: RequestInit): Promise<Array<DigitalResourceDto>> {
        const response = await this.apiClassSessionClassSessionIdResourcesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionGetRaw(requestParameters: ApiClassSessionGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassSessionDto>>> {
        const queryParameters: any = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassSessionDtoFromJSON));
    }

    /**
     */
    async apiClassSessionGet(requestParameters: ApiClassSessionGetRequest = {}, initOverrides?: RequestInit): Promise<Array<ClassSessionDto>> {
        const response = await this.apiClassSessionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionPostRaw(requestParameters: ApiClassSessionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassSessionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ClassSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassSessionDtoToJSON(requestParameters.classSessionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassSessionDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClassSessionPost(requestParameters: ApiClassSessionPostRequest = {}, initOverrides?: RequestInit): Promise<ClassSessionDto> {
        const response = await this.apiClassSessionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
