/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigitalPassportDto,
    DigitalPassportDtoFromJSON,
    DigitalPassportDtoFromJSONTyped,
    DigitalPassportDtoToJSON,
} from './DigitalPassportDto';
import {
    TrusteeDto,
    TrusteeDtoFromJSON,
    TrusteeDtoFromJSONTyped,
    TrusteeDtoToJSON,
} from './TrusteeDto';

/**
 * 
 * @export
 * @interface DigitalPassportTrusteeMappingDto
 */
export interface DigitalPassportTrusteeMappingDto {
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportTrusteeMappingDto
     */
    id?: string | null;
    /**
     * 
     * @type {TrusteeDto}
     * @memberof DigitalPassportTrusteeMappingDto
     */
    trustee?: TrusteeDto;
    /**
     * 
     * @type {DigitalPassportDto}
     * @memberof DigitalPassportTrusteeMappingDto
     */
    digitalPassport?: DigitalPassportDto;
}

export function DigitalPassportTrusteeMappingDtoFromJSON(json: any): DigitalPassportTrusteeMappingDto {
    return DigitalPassportTrusteeMappingDtoFromJSONTyped(json, false);
}

export function DigitalPassportTrusteeMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigitalPassportTrusteeMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'trustee': !exists(json, 'trustee') ? undefined : TrusteeDtoFromJSON(json['trustee']),
        'digitalPassport': !exists(json, 'digitalPassport') ? undefined : DigitalPassportDtoFromJSON(json['digitalPassport']),
    };
}

export function DigitalPassportTrusteeMappingDtoToJSON(value?: DigitalPassportTrusteeMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'trustee': TrusteeDtoToJSON(value.trustee),
        'digitalPassport': DigitalPassportDtoToJSON(value.digitalPassport),
    };
}

