/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Item,
    ItemFromJSON,
    ItemFromJSONTyped,
    ItemToJSON,
} from './Item';

/**
 * 
 * @export
 * @interface PaymentIntentCreateRequest
 */
export interface PaymentIntentCreateRequest {
    /**
     * 
     * @type {Array<Item>}
     * @memberof PaymentIntentCreateRequest
     */
    items?: Array<Item> | null;
}

export function PaymentIntentCreateRequestFromJSON(json: any): PaymentIntentCreateRequest {
    return PaymentIntentCreateRequestFromJSONTyped(json, false);
}

export function PaymentIntentCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentIntentCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(ItemFromJSON)),
    };
}

export function PaymentIntentCreateRequestToJSON(value?: PaymentIntentCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(ItemToJSON)),
    };
}

