/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as Link2, useNavigate, useParams } from "react-router-dom";
import { UserAuthenticationApi, UserDtoFromJSON } from "API";
import { AuthContext } from "Utilities/Auth";
import { useSnackbar } from "notistack";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
  passwordRepeat: yup
    .string()
    .required("Please enter your password again")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const Form = () => {
  const [apiConfig, login] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const userAuthenticationApi = new UserAuthenticationApi(apiConfig);
  const { token } = useParams();
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    password: "",
    passwordRepeat: "",
  };

  const processPasswordReset = (values) => {
    setLoading(true);
    userAuthenticationApi
      .apiUserAuthenticationResetPasswordTokenPost({
        userDto: new UserDtoFromJSON(values),
        token: token,
      })
      .then((result) => {
        enqueueSnackbar(
          "Your password has been changed successfully. Please login.",
          {
            variant: "success",
          }
        );
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Failed to change password. Please try again.", {
          variant: "error",
        });
      });
  };

  const onSubmit = (values) => {
    if (values.password != values.passwordRepeat) {
      enqueueSnackbar("Both passwords must match.", {
        variant: "error",
      });
    } else {
      processPasswordReset(values);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Recover account
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Password Reset
        </Typography>
        <Typography color="text.secondary">
          Enter a new password below, and we'll get you signed back in.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your new password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              type={"password"}
              name={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your new password again
            </Typography>
            <TextField
              label="Repeat Password *"
              variant="outlined"
              type={"password"}
              name={"passwordRepeat"}
              fullWidth
              value={formik.values.passwordRepeat}
              onChange={formik.handleChange}
              error={
                formik.touched.passwordRepeat &&
                Boolean(formik.errors.passwordRepeat)
              }
              helperText={
                formik.touched.passwordRepeat && formik.errors.passwordRepeat
              }
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  component={Link2}
                  size={"large"}
                  variant={"outlined"}
                  to={"/login"}
                  fullWidth
                >
                  Back to login
                </Button>
              </Box>
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                disabled={loading}
              >
                Send reset link
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
