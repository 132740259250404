/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaitlistSubmissionDto
 */
export interface WaitlistSubmissionDto {
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof WaitlistSubmissionDto
     */
    submitted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    parentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    studentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    grade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    subjectMatter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaitlistSubmissionDto
     */
    message?: string | null;
}

export function WaitlistSubmissionDtoFromJSON(json: any): WaitlistSubmissionDto {
    return WaitlistSubmissionDtoFromJSONTyped(json, false);
}

export function WaitlistSubmissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaitlistSubmissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'submitted': !exists(json, 'submitted') ? undefined : (json['submitted'] === null ? null : new Date(json['submitted'])),
        'parentName': !exists(json, 'parentName') ? undefined : json['parentName'],
        'studentName': !exists(json, 'studentName') ? undefined : json['studentName'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'subjectMatter': !exists(json, 'subjectMatter') ? undefined : json['subjectMatter'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function WaitlistSubmissionDtoToJSON(value?: WaitlistSubmissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'submitted': value.submitted === undefined ? undefined : (value.submitted === null ? null : value.submitted.toISOString()),
        'parentName': value.parentName,
        'studentName': value.studentName,
        'grade': value.grade,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
        'subjectMatter': value.subjectMatter,
        'message': value.message,
    };
}

