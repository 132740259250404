/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSession,
    ClassSessionFromJSON,
    ClassSessionFromJSONTyped,
    ClassSessionToJSON,
} from './ClassSession';
import {
    Payment,
    PaymentFromJSON,
    PaymentFromJSONTyped,
    PaymentToJSON,
} from './Payment';
import {
    Student,
    StudentFromJSON,
    StudentFromJSONTyped,
    StudentToJSON,
} from './Student';

/**
 * 
 * @export
 * @interface ClassSessionEnrollment
 */
export interface ClassSessionEnrollment {
    /**
     * 
     * @type {string}
     * @memberof ClassSessionEnrollment
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionEnrollment
     */
    classSessionId?: string | null;
    /**
     * 
     * @type {ClassSession}
     * @memberof ClassSessionEnrollment
     */
    classSession?: ClassSession;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionEnrollment
     */
    studentId?: string | null;
    /**
     * 
     * @type {Student}
     * @memberof ClassSessionEnrollment
     */
    student?: Student;
    /**
     * 
     * @type {number}
     * @memberof ClassSessionEnrollment
     */
    status?: number;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionEnrollment
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionEnrollment
     */
    paymentId?: string | null;
    /**
     * 
     * @type {Payment}
     * @memberof ClassSessionEnrollment
     */
    payment?: Payment;
}

export function ClassSessionEnrollmentFromJSON(json: any): ClassSessionEnrollment {
    return ClassSessionEnrollmentFromJSONTyped(json, false);
}

export function ClassSessionEnrollmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSessionEnrollment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'classSessionId': !exists(json, 'classSessionId') ? undefined : json['classSessionId'],
        'classSession': !exists(json, 'classSession') ? undefined : ClassSessionFromJSON(json['classSession']),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'student': !exists(json, 'student') ? undefined : StudentFromJSON(json['student']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'paymentId': !exists(json, 'paymentId') ? undefined : json['paymentId'],
        'payment': !exists(json, 'payment') ? undefined : PaymentFromJSON(json['payment']),
    };
}

export function ClassSessionEnrollmentToJSON(value?: ClassSessionEnrollment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'classSessionId': value.classSessionId,
        'classSession': ClassSessionToJSON(value.classSession),
        'studentId': value.studentId,
        'student': StudentToJSON(value.student),
        'status': value.status,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'paymentId': value.paymentId,
        'payment': PaymentToJSON(value.payment),
    };
}

