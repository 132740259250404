/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeLineItemsDto
 */
export interface StripeLineItemsDto {
    /**
     * 
     * @type {string}
     * @memberof StripeLineItemsDto
     */
    productName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeLineItemsDto
     */
    productId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeLineItemsDto
     */
    priceId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StripeLineItemsDto
     */
    amount?: number;
}

export function StripeLineItemsDtoFromJSON(json: any): StripeLineItemsDto {
    return StripeLineItemsDtoFromJSONTyped(json, false);
}

export function StripeLineItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeLineItemsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'priceId': !exists(json, 'priceId') ? undefined : json['priceId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function StripeLineItemsDtoToJSON(value?: StripeLineItemsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productName': value.productName,
        'productId': value.productId,
        'priceId': value.priceId,
        'amount': value.amount,
    };
}

