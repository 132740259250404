/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Student,
    StudentFromJSON,
    StudentFromJSONTyped,
    StudentToJSON,
} from './Student';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface UserStudentMapping
 */
export interface UserStudentMapping {
    /**
     * 
     * @type {string}
     * @memberof UserStudentMapping
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserStudentMapping
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof UserStudentMapping
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserStudentMapping
     */
    studentId?: string | null;
    /**
     * 
     * @type {Student}
     * @memberof UserStudentMapping
     */
    student?: Student;
}

export function UserStudentMappingFromJSON(json: any): UserStudentMapping {
    return UserStudentMappingFromJSONTyped(json, false);
}

export function UserStudentMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStudentMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'student': !exists(json, 'student') ? undefined : StudentFromJSON(json['student']),
    };
}

export function UserStudentMappingToJSON(value?: UserStudentMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'studentId': value.studentId,
        'student': StudentToJSON(value.student),
    };
}

