/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoFromJSONTyped,
    StudentDtoToJSON,
} from './StudentDto';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface UserStudentMappingDto
 */
export interface UserStudentMappingDto {
    /**
     * 
     * @type {string}
     * @memberof UserStudentMappingDto
     */
    id?: string | null;
    /**
     * 
     * @type {UserDto}
     * @memberof UserStudentMappingDto
     */
    user?: UserDto;
    /**
     * 
     * @type {StudentDto}
     * @memberof UserStudentMappingDto
     */
    student?: StudentDto;
}

export function UserStudentMappingDtoFromJSON(json: any): UserStudentMappingDto {
    return UserStudentMappingDtoFromJSONTyped(json, false);
}

export function UserStudentMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStudentMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : UserDtoFromJSON(json['user']),
        'student': !exists(json, 'student') ? undefined : StudentDtoFromJSON(json['student']),
    };
}

export function UserStudentMappingDtoToJSON(value?: UserStudentMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserDtoToJSON(value.user),
        'student': StudentDtoToJSON(value.student),
    };
}

