/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as Link2 } from "react-router-dom";
import { UserAuthenticationApi, UserDtoFromJSON } from "API";
import { AuthContext } from "Utilities/Auth";
import { useSnackbar } from "notistack";

const validationSchema = yup.object({
  emailAddress: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
});

const Form = () => {
  const [apiConfig, login] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const userAuthenticationApi = new UserAuthenticationApi(apiConfig);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    emailAddress: "",
  };

  const requestPasswordReset = (values) => {
    setLoading(true);
    userAuthenticationApi
      .apiUserAuthenticationResetPasswordPost({
        userDto: new UserDtoFromJSON(values),
      })
      .then((result) => {
        enqueueSnackbar("A password reset link has been sent to your email.", {
          variant: "success",
        });
        login(result);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar("Failed to reset password. Please try again.", {
          variant: "error",
        });
      });
  };

  const onSubmit = (values) => {
    requestPasswordReset(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Recover account
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Forgot your password?
        </Typography>
        <Typography color="text.secondary">
          Enter your email address below and we'll get you back on track.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email Address *"
              variant="outlined"
              name={"emailAddress"}
              fullWidth
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.emailAddress &&
                Boolean(formik.errors.emailAddress)
              }
              helperText={
                formik.touched.emailAddress && formik.errors.emailAddress
              }
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  component={Link2}
                  size={"large"}
                  variant={"outlined"}
                  to={"/login"}
                  fullWidth
                >
                  Back to login
                </Button>
              </Box>
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                disabled={loading}
              >
                Send reset link
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
