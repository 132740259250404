import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Container from "../../Components/Container";
import TopNav from "../../Components/TopNav";

import { Topbar, Sidebar, Footer } from "./components";

import pages from "../navigation";
import { Outlet } from "react-router-dom";

/* bgcolor alternative = "transparent" */
const Main = ({
  children,
  colorInvert = false,
  bgcolor = "background.paper",
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Box>
      <Box bgcolor={bgcolor} position={"relative"} zIndex={theme.zIndex.appBar}>
        {/*<Container paddingTop={"8px !important"} paddingBottom={"0 !important"}>
          <TopNav colorInvert={colorInvert} />
        </Container>*/}
      </Box>
      <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container paddingY={4}>
          <Topbar
            onSidebarOpen={handleSidebarOpen}
            pages={pages}
            colorInvert={trigger ? false : colorInvert}
          />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        {children}
        <Outlet />
      </main>
      <main style={{ marginBottom: "-7px" }}>
        <img src="/static/images/rollinghill.svg" width="100%" height="auto" />
      </main>
      <main style={{ backgroundColor: "#9EC95C" }}>
        <Container>
          <Footer />
        </Container>
      </main>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;
