import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link as Link2 } from "react-router-dom";

import NavItem from "./components/NavItem";

const SidebarNav = ({ pages, onClose }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={
              mode === "light"
                ? "/static/images/logo2.png"
                : "/static/images/logo2.png"
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box paddingY={2}>
          <Typography underline="none" fontWeight={400} color={"text.primary"}>
            <Link
              component={Link2}
              to={"/"}
              color={"text.primary"}
              underline={"none"}
              onClick={() => onClose()}
            >
              Home
            </Link>
          </Typography>
        </Box>
        <Box paddingY={2}>
          <Typography underline="none" fontWeight={400} color={"text.primary"}>
            <Link
              component={Link2}
              to={"/our-philosophy"}
              color={"text.primary"}
              underline={"none"}
              onClick={() => onClose()}
            >
              Our Philosophy
            </Link>
          </Typography>
        </Box>
        <Box paddingY={2}>
          <Typography underline="none" fontWeight={400} color={"text.primary"}>
            <Link
              component={Link2}
              to={"/testimonials"}
              color={"text.primary"}
              underline={"none"}
              onClick={() => onClose()}
            >
              Testimonials
            </Link>
          </Typography>
        </Box>
        <Box paddingY={2}>
          <Typography underline="none" fontWeight={400} color={"text.primary"}>
            <Link
              component={Link2}
              to={"/faq"}
              color={"text.primary"}
              underline={"none"}
              onClick={() => onClose()}
            >
              FAQ
            </Link>
          </Typography>
        </Box>
        <Box paddingY={2}>
          <Typography underline="none" fontWeight={400} color={"text.primary"}>
            <Link
              component={Link2}
              to={"/contact-us"}
              color={"text.primary"}
              underline={"none"}
              onClick={() => onClose()}
            >
              Contact
            </Link>
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Button
            size={"large"}
            variant="outlined"
            fullWidth
            component={Link2}
            to={"/dashboard"}
            onClick={() => onClose()}
          >
            Student Portal
          </Button>
        </Box>
        <Box marginTop={1}>
          <Button
            size={"large"}
            variant="contained"
            color="primary"
            fullWidth
            component={Link2}
            to={"/sessions"}
            onClick={() => onClose()}
          >
            Enroll Now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
