/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentDto,
    PaymentDtoFromJSON,
    PaymentDtoFromJSONTyped,
    PaymentDtoToJSON,
} from './PaymentDto';
import {
    TrusteeUserMappingDto,
    TrusteeUserMappingDtoFromJSON,
    TrusteeUserMappingDtoFromJSONTyped,
    TrusteeUserMappingDtoToJSON,
} from './TrusteeUserMappingDto';
import {
    UserStudentMappingDto,
    UserStudentMappingDtoFromJSON,
    UserStudentMappingDtoFromJSONTyped,
    UserStudentMappingDtoToJSON,
} from './UserStudentMappingDto';

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    type?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    sms?: string | null;
    /**
     * 
     * @type {Array<UserStudentMappingDto>}
     * @memberof UserDto
     */
    userStudentMappings?: Array<UserStudentMappingDto> | null;
    /**
     * 
     * @type {Array<TrusteeUserMappingDto>}
     * @memberof UserDto
     */
    trusteeUserMappings?: Array<TrusteeUserMappingDto> | null;
    /**
     * 
     * @type {Array<PaymentDto>}
     * @memberof UserDto
     */
    payments?: Array<PaymentDto> | null;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'sms': !exists(json, 'sms') ? undefined : json['sms'],
        'userStudentMappings': !exists(json, 'userStudentMappings') ? undefined : (json['userStudentMappings'] === null ? null : (json['userStudentMappings'] as Array<any>).map(UserStudentMappingDtoFromJSON)),
        'trusteeUserMappings': !exists(json, 'trusteeUserMappings') ? undefined : (json['trusteeUserMappings'] === null ? null : (json['trusteeUserMappings'] as Array<any>).map(TrusteeUserMappingDtoFromJSON)),
        'payments': !exists(json, 'payments') ? undefined : (json['payments'] === null ? null : (json['payments'] as Array<any>).map(PaymentDtoFromJSON)),
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'password': value.password,
        'sms': value.sms,
        'userStudentMappings': value.userStudentMappings === undefined ? undefined : (value.userStudentMappings === null ? null : (value.userStudentMappings as Array<any>).map(UserStudentMappingDtoToJSON)),
        'trusteeUserMappings': value.trusteeUserMappings === undefined ? undefined : (value.trusteeUserMappings === null ? null : (value.trusteeUserMappings as Array<any>).map(TrusteeUserMappingDtoToJSON)),
        'payments': value.payments === undefined ? undefined : (value.payments === null ? null : (value.payments as Array<any>).map(PaymentDtoToJSON)),
    };
}

