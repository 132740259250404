/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSessionEnrollmentDto,
    ClassSessionEnrollmentDtoFromJSON,
    ClassSessionEnrollmentDtoFromJSONTyped,
    ClassSessionEnrollmentDtoToJSON,
} from './ClassSessionEnrollmentDto';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    originalAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentDto
     */
    finalAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    checkoutUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    checkoutId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    paymentIntentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    checkoutJSON?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    chargeJSON?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    receiptUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PaymentDto
     */
    created?: Date | null;
    /**
     * 
     * @type {UserDto}
     * @memberof PaymentDto
     */
    user?: UserDto;
    /**
     * 
     * @type {Array<ClassSessionEnrollmentDto>}
     * @memberof PaymentDto
     */
    classSessionEnrollments?: Array<ClassSessionEnrollmentDto> | null;
}

export function PaymentDtoFromJSON(json: any): PaymentDto {
    return PaymentDtoFromJSONTyped(json, false);
}

export function PaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'originalAmount': !exists(json, 'originalAmount') ? undefined : json['originalAmount'],
        'finalAmount': !exists(json, 'finalAmount') ? undefined : json['finalAmount'],
        'checkoutUrl': !exists(json, 'checkoutUrl') ? undefined : json['checkoutUrl'],
        'checkoutId': !exists(json, 'checkoutId') ? undefined : json['checkoutId'],
        'paymentIntentId': !exists(json, 'paymentIntentId') ? undefined : json['paymentIntentId'],
        'checkoutJSON': !exists(json, 'checkoutJSON') ? undefined : json['checkoutJSON'],
        'chargeJSON': !exists(json, 'chargeJSON') ? undefined : json['chargeJSON'],
        'receiptUrl': !exists(json, 'receiptUrl') ? undefined : json['receiptUrl'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'created': !exists(json, 'created') ? undefined : (json['created'] === null ? null : new Date(json['created'])),
        'user': !exists(json, 'user') ? undefined : UserDtoFromJSON(json['user']),
        'classSessionEnrollments': !exists(json, 'classSessionEnrollments') ? undefined : (json['classSessionEnrollments'] === null ? null : (json['classSessionEnrollments'] as Array<any>).map(ClassSessionEnrollmentDtoFromJSON)),
    };
}

export function PaymentDtoToJSON(value?: PaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'originalAmount': value.originalAmount,
        'finalAmount': value.finalAmount,
        'checkoutUrl': value.checkoutUrl,
        'checkoutId': value.checkoutId,
        'paymentIntentId': value.paymentIntentId,
        'checkoutJSON': value.checkoutJSON,
        'chargeJSON': value.chargeJSON,
        'receiptUrl': value.receiptUrl,
        'phoneNumber': value.phoneNumber,
        'emailAddress': value.emailAddress,
        'created': value.created === undefined ? undefined : (value.created === null ? null : value.created.toISOString()),
        'user': UserDtoToJSON(value.user),
        'classSessionEnrollments': value.classSessionEnrollments === undefined ? undefined : (value.classSessionEnrollments === null ? null : (value.classSessionEnrollments as Array<any>).map(ClassSessionEnrollmentDtoToJSON)),
    };
}

