import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Link, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import { NavItem } from "./components";
import { Link as Link2 } from "react-router-dom";
import ThemeModeToggler from "Components/ThemeModeToggler";

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component={Link2}
        to="/"
        title="Bloom"
        width={{ xs: 100, md: 150 }}
      >
        <Box
          component={"img"}
          src={
            mode === "light" && !colorInvert
              ? "/static/images/logo2.png"
              : "/static/images/logo2.png"
          }
          alt="Logo"
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        <Box>
          <Link
            underline="none"
            component={Link2}
            to="/our-philosophy"
            color={colorInvert ? "common.white" : "text.primary"}
            sx={{ display: "flex", alignItems: "center", fontWeight: 400 }}
          >
            Our Philosophy
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component={Link2}
            to="/testimonials"
            color={colorInvert ? "common.white" : "text.primary"}
            sx={{ display: "flex", alignItems: "center", fontWeight: 400 }}
          >
            Testimonials
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component={Link2}
            to="/faq"
            color={colorInvert ? "common.white" : "text.primary"}
            sx={{ display: "flex", alignItems: "center", fontWeight: 400 }}
          >
            FAQ
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component={Link2}
            to="/contact-us"
            color={colorInvert ? "common.white" : "text.primary"}
            sx={{ display: "flex", alignItems: "center", fontWeight: 400 }}
          >
            Contact
          </Link>
        </Box>
        <Box marginLeft={4}>
          <Link
            underline="none"
            component={Link2}
            to="/dashboard"
            color={colorInvert ? "common.white" : "text.primary"}
            sx={{ display: "flex", alignItems: "center", fontWeight: 400 }}
          >
            Student Portal
          </Link>
        </Box>
        {/*<Box marginLeft={4}>
          <NavItem
            title={"Account"}
            id={"account-pages"}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Pages"}
            id={"secondary-pages"}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={"Blog"}
            id={"blog-pages"}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box>*/}
        <Box marginLeft={4}>
          <Button
            variant="blue"
            color="primary"
            component={Link2}
            to={"/sessions"}
            size="large"
          >
            Enroll Now
          </Button>
        </Box>
        <Box marginLeft={4}>
          <Button
            variant="yellow"
            color={colorInvert ? "common.white" : "text.primary"}
            component={Link2}
            to={"/dashboard"}
            size="large"
          >
            Student Portal
          </Button>
        </Box>
        {/*<Box marginLeft={2}>
          <ThemeModeToggler />
        </Box>*/}
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
