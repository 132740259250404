/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttendanceDto,
    AttendanceDtoFromJSON,
    AttendanceDtoFromJSONTyped,
    AttendanceDtoToJSON,
} from './AttendanceDto';
import {
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoFromJSONTyped,
    StudentDtoToJSON,
} from './StudentDto';

/**
 * 
 * @export
 * @interface AttendanceRosterDto
 */
export interface AttendanceRosterDto {
    /**
     * 
     * @type {Array<StudentDto>}
     * @memberof AttendanceRosterDto
     */
    students?: Array<StudentDto> | null;
    /**
     * 
     * @type {Array<AttendanceDto>}
     * @memberof AttendanceRosterDto
     */
    attendances?: Array<AttendanceDto> | null;
}

export function AttendanceRosterDtoFromJSON(json: any): AttendanceRosterDto {
    return AttendanceRosterDtoFromJSONTyped(json, false);
}

export function AttendanceRosterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttendanceRosterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'students': !exists(json, 'students') ? undefined : (json['students'] === null ? null : (json['students'] as Array<any>).map(StudentDtoFromJSON)),
        'attendances': !exists(json, 'attendances') ? undefined : (json['attendances'] === null ? null : (json['attendances'] as Array<any>).map(AttendanceDtoFromJSON)),
    };
}

export function AttendanceRosterDtoToJSON(value?: AttendanceRosterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'students': value.students === undefined ? undefined : (value.students === null ? null : (value.students as Array<any>).map(StudentDtoToJSON)),
        'attendances': value.attendances === undefined ? undefined : (value.attendances === null ? null : (value.attendances as Array<any>).map(AttendanceDtoToJSON)),
    };
}

