/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Grid, TextField, Button, Typography, Link } from "@mui/material";
import { useSnackbar } from "notistack";
import { Link as Link2, useLocation, useNavigate } from "react-router-dom";
import { UserAuthenticationApi, UserDtoFromJSON } from "API";
import { AuthContext } from "Utilities/Auth";

const validationSchema = yup.object({
  emailAddress: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup.string().required("Please specify your password"),
});

const Form = () => {
  const [apiConfig, logon, ,] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const userAuthenticationApi = new UserAuthenticationApi(apiConfig);
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/dashboard";

  const initialValues = {
    emailAddress: "",
    password: "",
  };

  const onSubmit = (values) => {
    processLogin(values);
  };

  const processLogin = (values) => {
    setLoading(true);
    userAuthenticationApi
      .apiUserAuthenticationLoginPost({
        userDto: new UserDtoFromJSON(values),
      })
      .then((result) => {
        logon(result);
        enqueueSnackbar("Logged in successfully.", {
          variant: "success",
        });
        navigate(from);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(
          "Failed to login. Please check your credentials and try again.",
          { variant: "error" }
        );
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        {/*<Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "medium",
          }}
          gutterBottom
          color={"text.secondary"}
        >
          Login
        </Typography>*/}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Log in to access the Bloom Portal
        </Typography>
        {/*<Typography color="text.secondary">Please login to proceed.</Typography>*/}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant={"subtitle2"}
              sx={{ marginBottom: 2 }}
            ></Typography>
            <TextField
              label="Email Address *"
              variant="outlined"
              name={"emailAddress"}
              fullWidth
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.emailAddress &&
                Boolean(formik.errors.emailAddress)
              }
              helperText={
                formik.touched.emailAddress && formik.errors.emailAddress
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}></Typography>
              </Box>
            </Box>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={"subtitle2"}>
                  <Link
                    component={Link2}
                    color={"primary"}
                    to={"/password-reset"}
                    underline={"none"}
                  >
                    Forgot your password?
                  </Link>
                </Typography>
                <Typography variant={"subtitle2"}>
                  Don't have an account yet?{" "}
                  <Link
                    component={Link2}
                    color={"primary"}
                    to={"/register?redirect=" + from}
                    underline={"none"}
                  >
                    Sign up here.
                  </Link>
                </Typography>
              </Box>
              <Button
                size={"large"}
                variant={"blue"}
                type={"submit"}
                disabled={loading}
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
