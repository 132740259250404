/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSessionDto,
    ClassSessionDtoFromJSON,
    ClassSessionDtoFromJSONTyped,
    ClassSessionDtoToJSON,
} from './ClassSessionDto';
import {
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoFromJSONTyped,
    StudentDtoToJSON,
} from './StudentDto';

/**
 * 
 * @export
 * @interface ClassSessionWaitlistSubmissionDto
 */
export interface ClassSessionWaitlistSubmissionDto {
    /**
     * 
     * @type {string}
     * @memberof ClassSessionWaitlistSubmissionDto
     */
    id?: string | null;
    /**
     * 
     * @type {ClassSessionDto}
     * @memberof ClassSessionWaitlistSubmissionDto
     */
    classSession?: ClassSessionDto;
    /**
     * 
     * @type {StudentDto}
     * @memberof ClassSessionWaitlistSubmissionDto
     */
    student?: StudentDto;
    /**
     * 
     * @type {Array<StudentDto>}
     * @memberof ClassSessionWaitlistSubmissionDto
     */
    students?: Array<StudentDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassSessionWaitlistSubmissionDto
     */
    status?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionWaitlistSubmissionDto
     */
    created?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionWaitlistSubmissionDto
     */
    expires?: Date | null;
}

export function ClassSessionWaitlistSubmissionDtoFromJSON(json: any): ClassSessionWaitlistSubmissionDto {
    return ClassSessionWaitlistSubmissionDtoFromJSONTyped(json, false);
}

export function ClassSessionWaitlistSubmissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSessionWaitlistSubmissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'classSession': !exists(json, 'classSession') ? undefined : ClassSessionDtoFromJSON(json['classSession']),
        'student': !exists(json, 'student') ? undefined : StudentDtoFromJSON(json['student']),
        'students': !exists(json, 'students') ? undefined : (json['students'] === null ? null : (json['students'] as Array<any>).map(StudentDtoFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : (json['created'] === null ? null : new Date(json['created'])),
        'expires': !exists(json, 'expires') ? undefined : (json['expires'] === null ? null : new Date(json['expires'])),
    };
}

export function ClassSessionWaitlistSubmissionDtoToJSON(value?: ClassSessionWaitlistSubmissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'classSession': ClassSessionDtoToJSON(value.classSession),
        'student': StudentDtoToJSON(value.student),
        'students': value.students === undefined ? undefined : (value.students === null ? null : (value.students as Array<any>).map(StudentDtoToJSON)),
        'status': value.status,
        'created': value.created === undefined ? undefined : (value.created === null ? null : value.created.toISOString()),
        'expires': value.expires === undefined ? undefined : (value.expires === null ? null : value.expires.toISOString()),
    };
}

