/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Announcement,
    AnnouncementFromJSON,
    AnnouncementFromJSONTyped,
    AnnouncementToJSON,
} from './Announcement';
import {
    ClassPolicy,
    ClassPolicyFromJSON,
    ClassPolicyFromJSONTyped,
    ClassPolicyToJSON,
} from './ClassPolicy';
import {
    ClassSection,
    ClassSectionFromJSON,
    ClassSectionFromJSONTyped,
    ClassSectionToJSON,
} from './ClassSection';
import {
    ClassSessionEnrollment,
    ClassSessionEnrollmentFromJSON,
    ClassSessionEnrollmentFromJSONTyped,
    ClassSessionEnrollmentToJSON,
} from './ClassSessionEnrollment';
import {
    ClassSessionWaitlistSubmission,
    ClassSessionWaitlistSubmissionFromJSON,
    ClassSessionWaitlistSubmissionFromJSONTyped,
    ClassSessionWaitlistSubmissionToJSON,
} from './ClassSessionWaitlistSubmission';
import {
    DigitalResource,
    DigitalResourceFromJSON,
    DigitalResourceFromJSONTyped,
    DigitalResourceToJSON,
} from './DigitalResource';

/**
 * 
 * @export
 * @interface ClassSession
 */
export interface ClassSession {
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    thumbnail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSession
     */
    sessionStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassSession
     */
    sessionEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassSession
     */
    priorityRegistrationStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassSession
     */
    priorityRegistrationEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassSession
     */
    registrationStartDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClassSession
     */
    registrationEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    daySchedule?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassSession
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    stripeLineItem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    supplyFeeLineItem?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassSession
     */
    registrationPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassSession
     */
    supplyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassSession
     */
    rosterSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClassSession
     */
    waitlistActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassSession
     */
    waitlistSize?: number;
    /**
     * 
     * @type {Array<ClassSessionEnrollment>}
     * @memberof ClassSession
     */
    classSessionEnrollments?: Array<ClassSessionEnrollment> | null;
    /**
     * 
     * @type {Array<ClassSessionWaitlistSubmission>}
     * @memberof ClassSession
     */
    classSessionWaitlistSubmissions?: Array<ClassSessionWaitlistSubmission> | null;
    /**
     * 
     * @type {Array<DigitalResource>}
     * @memberof ClassSession
     */
    digitalResources?: Array<DigitalResource> | null;
    /**
     * 
     * @type {Array<Announcement>}
     * @memberof ClassSession
     */
    announcements?: Array<Announcement> | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSession
     */
    classSectionId?: string | null;
    /**
     * 
     * @type {ClassSection}
     * @memberof ClassSession
     */
    classSection?: ClassSection;
    /**
     * 
     * @type {ClassPolicy}
     * @memberof ClassSession
     */
    expectationPolicy?: ClassPolicy;
    /**
     * 
     * @type {ClassPolicy}
     * @memberof ClassSession
     */
    dismissalPolicy?: ClassPolicy;
    /**
     * 
     * @type {ClassPolicy}
     * @memberof ClassSession
     */
    latePickupPolicy?: ClassPolicy;
}

export function ClassSessionFromJSON(json: any): ClassSession {
    return ClassSessionFromJSONTyped(json, false);
}

export function ClassSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'sessionStartDate': !exists(json, 'sessionStartDate') ? undefined : (new Date(json['sessionStartDate'])),
        'sessionEndDate': !exists(json, 'sessionEndDate') ? undefined : (new Date(json['sessionEndDate'])),
        'priorityRegistrationStartDate': !exists(json, 'priorityRegistrationStartDate') ? undefined : (new Date(json['priorityRegistrationStartDate'])),
        'priorityRegistrationEndDate': !exists(json, 'priorityRegistrationEndDate') ? undefined : (new Date(json['priorityRegistrationEndDate'])),
        'registrationStartDate': !exists(json, 'registrationStartDate') ? undefined : (new Date(json['registrationStartDate'])),
        'registrationEndDate': !exists(json, 'registrationEndDate') ? undefined : (new Date(json['registrationEndDate'])),
        'daySchedule': !exists(json, 'daySchedule') ? undefined : json['daySchedule'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'stripeLineItem': !exists(json, 'stripeLineItem') ? undefined : json['stripeLineItem'],
        'supplyFeeLineItem': !exists(json, 'supplyFeeLineItem') ? undefined : json['supplyFeeLineItem'],
        'registrationPrice': !exists(json, 'registrationPrice') ? undefined : json['registrationPrice'],
        'supplyFee': !exists(json, 'supplyFee') ? undefined : json['supplyFee'],
        'rosterSize': !exists(json, 'rosterSize') ? undefined : json['rosterSize'],
        'waitlistActive': !exists(json, 'waitlistActive') ? undefined : json['waitlistActive'],
        'waitlistSize': !exists(json, 'waitlistSize') ? undefined : json['waitlistSize'],
        'classSessionEnrollments': !exists(json, 'classSessionEnrollments') ? undefined : (json['classSessionEnrollments'] === null ? null : (json['classSessionEnrollments'] as Array<any>).map(ClassSessionEnrollmentFromJSON)),
        'classSessionWaitlistSubmissions': !exists(json, 'classSessionWaitlistSubmissions') ? undefined : (json['classSessionWaitlistSubmissions'] === null ? null : (json['classSessionWaitlistSubmissions'] as Array<any>).map(ClassSessionWaitlistSubmissionFromJSON)),
        'digitalResources': !exists(json, 'digitalResources') ? undefined : (json['digitalResources'] === null ? null : (json['digitalResources'] as Array<any>).map(DigitalResourceFromJSON)),
        'announcements': !exists(json, 'announcements') ? undefined : (json['announcements'] === null ? null : (json['announcements'] as Array<any>).map(AnnouncementFromJSON)),
        'classSectionId': !exists(json, 'classSectionId') ? undefined : json['classSectionId'],
        'classSection': !exists(json, 'classSection') ? undefined : ClassSectionFromJSON(json['classSection']),
        'expectationPolicy': !exists(json, 'expectationPolicy') ? undefined : ClassPolicyFromJSON(json['expectationPolicy']),
        'dismissalPolicy': !exists(json, 'dismissalPolicy') ? undefined : ClassPolicyFromJSON(json['dismissalPolicy']),
        'latePickupPolicy': !exists(json, 'latePickupPolicy') ? undefined : ClassPolicyFromJSON(json['latePickupPolicy']),
    };
}

export function ClassSessionToJSON(value?: ClassSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'thumbnail': value.thumbnail,
        'sessionStartDate': value.sessionStartDate === undefined ? undefined : (value.sessionStartDate.toISOString()),
        'sessionEndDate': value.sessionEndDate === undefined ? undefined : (value.sessionEndDate.toISOString()),
        'priorityRegistrationStartDate': value.priorityRegistrationStartDate === undefined ? undefined : (value.priorityRegistrationStartDate.toISOString()),
        'priorityRegistrationEndDate': value.priorityRegistrationEndDate === undefined ? undefined : (value.priorityRegistrationEndDate.toISOString()),
        'registrationStartDate': value.registrationStartDate === undefined ? undefined : (value.registrationStartDate.toISOString()),
        'registrationEndDate': value.registrationEndDate === undefined ? undefined : (value.registrationEndDate.toISOString()),
        'daySchedule': value.daySchedule,
        'isActive': value.isActive,
        'stripeLineItem': value.stripeLineItem,
        'supplyFeeLineItem': value.supplyFeeLineItem,
        'registrationPrice': value.registrationPrice,
        'supplyFee': value.supplyFee,
        'rosterSize': value.rosterSize,
        'waitlistActive': value.waitlistActive,
        'waitlistSize': value.waitlistSize,
        'classSessionEnrollments': value.classSessionEnrollments === undefined ? undefined : (value.classSessionEnrollments === null ? null : (value.classSessionEnrollments as Array<any>).map(ClassSessionEnrollmentToJSON)),
        'classSessionWaitlistSubmissions': value.classSessionWaitlistSubmissions === undefined ? undefined : (value.classSessionWaitlistSubmissions === null ? null : (value.classSessionWaitlistSubmissions as Array<any>).map(ClassSessionWaitlistSubmissionToJSON)),
        'digitalResources': value.digitalResources === undefined ? undefined : (value.digitalResources === null ? null : (value.digitalResources as Array<any>).map(DigitalResourceToJSON)),
        'announcements': value.announcements === undefined ? undefined : (value.announcements === null ? null : (value.announcements as Array<any>).map(AnnouncementToJSON)),
        'classSectionId': value.classSectionId,
        'classSection': ClassSectionToJSON(value.classSection),
        'expectationPolicy': ClassPolicyToJSON(value.expectationPolicy),
        'dismissalPolicy': ClassPolicyToJSON(value.dismissalPolicy),
        'latePickupPolicy': ClassPolicyToJSON(value.latePickupPolicy),
    };
}

