/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSession,
    ClassSessionFromJSON,
    ClassSessionFromJSONTyped,
    ClassSessionToJSON,
} from './ClassSession';

/**
 * 
 * @export
 * @interface ClassPolicy
 */
export interface ClassPolicy {
    /**
     * 
     * @type {string}
     * @memberof ClassPolicy
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassPolicy
     */
    policyType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassPolicy
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassPolicy
     */
    htmlValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassPolicy
     */
    created?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassPolicy
     */
    lastUpdated?: string | null;
    /**
     * 
     * @type {Array<ClassSession>}
     * @memberof ClassPolicy
     */
    classSessionDismissalPolicies?: Array<ClassSession> | null;
    /**
     * 
     * @type {Array<ClassSession>}
     * @memberof ClassPolicy
     */
    classSessionExpectationPolicies?: Array<ClassSession> | null;
    /**
     * 
     * @type {Array<ClassSession>}
     * @memberof ClassPolicy
     */
    classSessionLatePickupPolicies?: Array<ClassSession> | null;
}

export function ClassPolicyFromJSON(json: any): ClassPolicy {
    return ClassPolicyFromJSONTyped(json, false);
}

export function ClassPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassPolicy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'policyType': !exists(json, 'policyType') ? undefined : json['policyType'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'htmlValue': !exists(json, 'htmlValue') ? undefined : json['htmlValue'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : json['lastUpdated'],
        'classSessionDismissalPolicies': !exists(json, 'classSessionDismissalPolicies') ? undefined : (json['classSessionDismissalPolicies'] === null ? null : (json['classSessionDismissalPolicies'] as Array<any>).map(ClassSessionFromJSON)),
        'classSessionExpectationPolicies': !exists(json, 'classSessionExpectationPolicies') ? undefined : (json['classSessionExpectationPolicies'] === null ? null : (json['classSessionExpectationPolicies'] as Array<any>).map(ClassSessionFromJSON)),
        'classSessionLatePickupPolicies': !exists(json, 'classSessionLatePickupPolicies') ? undefined : (json['classSessionLatePickupPolicies'] === null ? null : (json['classSessionLatePickupPolicies'] as Array<any>).map(ClassSessionFromJSON)),
    };
}

export function ClassPolicyToJSON(value?: ClassPolicy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'policyType': value.policyType,
        'name': value.name,
        'htmlValue': value.htmlValue,
        'created': value.created,
        'lastUpdated': value.lastUpdated,
        'classSessionDismissalPolicies': value.classSessionDismissalPolicies === undefined ? undefined : (value.classSessionDismissalPolicies === null ? null : (value.classSessionDismissalPolicies as Array<any>).map(ClassSessionToJSON)),
        'classSessionExpectationPolicies': value.classSessionExpectationPolicies === undefined ? undefined : (value.classSessionExpectationPolicies === null ? null : (value.classSessionExpectationPolicies as Array<any>).map(ClassSessionToJSON)),
        'classSessionLatePickupPolicies': value.classSessionLatePickupPolicies === undefined ? undefined : (value.classSessionLatePickupPolicies === null ? null : (value.classSessionLatePickupPolicies as Array<any>).map(ClassSessionToJSON)),
    };
}

