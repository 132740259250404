/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigitalPassportTrusteeMappingDto,
    DigitalPassportTrusteeMappingDtoFromJSON,
    DigitalPassportTrusteeMappingDtoFromJSONTyped,
    DigitalPassportTrusteeMappingDtoToJSON,
} from './DigitalPassportTrusteeMappingDto';

/**
 * 
 * @export
 * @interface DigitalPassportDto
 */
export interface DigitalPassportDto {
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DigitalPassportDto
     */
    isActive?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    data?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    applePassUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    applePassAuthenticationToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    applePassIdenitifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    applePassSerialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportDto
     */
    googlePassUrl?: string | null;
    /**
     * 
     * @type {Array<DigitalPassportTrusteeMappingDto>}
     * @memberof DigitalPassportDto
     */
    digitalPassportTrusteeMappings?: Array<DigitalPassportTrusteeMappingDto> | null;
}

export function DigitalPassportDtoFromJSON(json: any): DigitalPassportDto {
    return DigitalPassportDtoFromJSONTyped(json, false);
}

export function DigitalPassportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigitalPassportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'applePassUrl': !exists(json, 'applePassUrl') ? undefined : json['applePassUrl'],
        'applePassAuthenticationToken': !exists(json, 'applePassAuthenticationToken') ? undefined : json['applePassAuthenticationToken'],
        'applePassIdenitifier': !exists(json, 'applePassIdenitifier') ? undefined : json['applePassIdenitifier'],
        'applePassSerialNumber': !exists(json, 'applePassSerialNumber') ? undefined : json['applePassSerialNumber'],
        'googlePassUrl': !exists(json, 'googlePassUrl') ? undefined : json['googlePassUrl'],
        'digitalPassportTrusteeMappings': !exists(json, 'digitalPassportTrusteeMappings') ? undefined : (json['digitalPassportTrusteeMappings'] === null ? null : (json['digitalPassportTrusteeMappings'] as Array<any>).map(DigitalPassportTrusteeMappingDtoFromJSON)),
    };
}

export function DigitalPassportDtoToJSON(value?: DigitalPassportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'imageUrl': value.imageUrl,
        'data': value.data,
        'applePassUrl': value.applePassUrl,
        'applePassAuthenticationToken': value.applePassAuthenticationToken,
        'applePassIdenitifier': value.applePassIdenitifier,
        'applePassSerialNumber': value.applePassSerialNumber,
        'googlePassUrl': value.googlePassUrl,
        'digitalPassportTrusteeMappings': value.digitalPassportTrusteeMappings === undefined ? undefined : (value.digitalPassportTrusteeMappings === null ? null : (value.digitalPassportTrusteeMappings as Array<any>).map(DigitalPassportTrusteeMappingDtoToJSON)),
    };
}

