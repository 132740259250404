/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSessionDto,
    ClassSessionDtoFromJSON,
    ClassSessionDtoFromJSONTyped,
    ClassSessionDtoToJSON,
} from './ClassSessionDto';

/**
 * 
 * @export
 * @interface DigitalResourceDto
 */
export interface DigitalResourceDto {
    /**
     * 
     * @type {string}
     * @memberof DigitalResourceDto
     */
    id?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DigitalResourceDto
     */
    type?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalResourceDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalResourceDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalResourceDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalResourceDto
     */
    url?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DigitalResourceDto
     */
    isActive?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof DigitalResourceDto
     */
    uploadDate?: Date | null;
    /**
     * 
     * @type {ClassSessionDto}
     * @memberof DigitalResourceDto
     */
    classSession?: ClassSessionDto;
}

export function DigitalResourceDtoFromJSON(json: any): DigitalResourceDto {
    return DigitalResourceDtoFromJSONTyped(json, false);
}

export function DigitalResourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigitalResourceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (json['uploadDate'] === null ? null : new Date(json['uploadDate'])),
        'classSession': !exists(json, 'classSession') ? undefined : ClassSessionDtoFromJSON(json['classSession']),
    };
}

export function DigitalResourceDtoToJSON(value?: DigitalResourceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'name': value.name,
        'fileName': value.fileName,
        'description': value.description,
        'url': value.url,
        'isActive': value.isActive,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate === null ? null : value.uploadDate.toISOString()),
        'classSession': ClassSessionDtoToJSON(value.classSession),
    };
}

