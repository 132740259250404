import React, { useState, useEffect, createContext, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Configuration } from "../API";
import { useSnackbar } from "notistack";

export const AuthContext = createContext();
const API_ENDPOINT =
  process.env.NODE_ENV == "production" ? "" : "https://localhost:7091";

export const AuthProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("jwt"));
  const [apiConfig, setApiConfig] = useState(
    new Configuration({
      basePath: API_ENDPOINT,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
  );

  const logon = (newToken) => {
    setToken(newToken);
    localStorage.setItem("jwt", newToken);
    setApiConfig(
      new Configuration({
        basePath: API_ENDPOINT,
        headers: {
          Authorization: "Bearer " + newToken,
        },
      })
    );
  };

  const logout = () => {
    setApiConfig(
      new Configuration({
        basePath: API_ENDPOINT,
      })
    );
    setToken(null);
    localStorage.setItem("jwt", null);
  };

  return (
    <AuthContext.Provider value={[apiConfig, logon, logout, token]}>
      {props.children}
    </AuthContext.Provider>
  );
};

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export function RequireAuth({ children }) {
  const [, , , currentToken] = useContext(AuthContext);
  let location = useLocation();
  let { enqueueSnackbar } = useSnackbar();

  //const { decodedToken, isExpired } = useJwt(currentToken);
  let decodedToken = parseJwt(currentToken);
  let current_time = new Date().getTime() / 1000;

  // Ensure JWT token exists; redirect to login otherwise
  if (decodedToken == null) {
    //enqueueSnackbar("You must login to view this page", { variant: "info" });
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Ensure JWT token is valid/not expired; redirect to login otherwise
  if (current_time > decodedToken.exp) {
    /*enqueueSnackbar("Your session has expired. Please login again.", {
      variant: "info",
    });*/
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
