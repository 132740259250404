/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Student,
    StudentFromJSON,
    StudentFromJSONTyped,
    StudentToJSON,
} from './Student';

/**
 * 
 * @export
 * @interface Allergy
 */
export interface Allergy {
    /**
     * 
     * @type {string}
     * @memberof Allergy
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Allergy
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Allergy
     */
    studentId?: string | null;
    /**
     * 
     * @type {Student}
     * @memberof Allergy
     */
    student?: Student;
}

export function AllergyFromJSON(json: any): Allergy {
    return AllergyFromJSONTyped(json, false);
}

export function AllergyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Allergy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'student': !exists(json, 'student') ? undefined : StudentFromJSON(json['student']),
    };
}

export function AllergyToJSON(value?: Allergy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'studentId': value.studentId,
        'student': StudentToJSON(value.student),
    };
}

