/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppleDeviceRegistrationDto,
    AppleDeviceRegistrationDtoFromJSON,
    AppleDeviceRegistrationDtoToJSON,
    AppleDeviceRosterDto,
    AppleDeviceRosterDtoFromJSON,
    AppleDeviceRosterDtoToJSON,
    DigitalPassportDto,
    DigitalPassportDtoFromJSON,
    DigitalPassportDtoToJSON,
    TrusteeStudentMappingDto,
    TrusteeStudentMappingDtoFromJSON,
    TrusteeStudentMappingDtoToJSON,
} from '../models';

export interface ApiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDeleteRequest {
    version: string;
    deviceId: string;
    passTypeId: string;
    serialNumber: string;
}

export interface ApiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPostRequest {
    deviceId: string;
    passTypeId: string;
    serialNumber: string;
    version: string;
    appleDeviceRegistrationDto?: AppleDeviceRegistrationDto;
}

export interface ApiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGetRequest {
    version: string;
    passTypeId: string;
    serialNumber: string;
    deviceId?: string;
}

export interface ApiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGetRequest {
    version: string;
    passTypeId: string;
    serialNumber: string;
}

export interface ApiDigitalPassportResendPostRequest {
    trusteeStudentMappingDto?: TrusteeStudentMappingDto;
}

export interface ApiDigitalPassportTrusteeTrusteeIdGetRequest {
    trusteeId: string;
}

/**
 * 
 */
export class DigitalPassportApi extends runtime.BaseAPI {

    /**
     */
    async apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDeleteRaw(requestParameters: ApiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDelete.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDelete.');
        }

        if (requestParameters.passTypeId === null || requestParameters.passTypeId === undefined) {
            throw new runtime.RequiredError('passTypeId','Required parameter requestParameters.passTypeId was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDelete.');
        }

        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/DigitalPassport/PassKit/{version}/devices/{deviceId}/registrations/{passTypeId}/{serialNumber}`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"passTypeId"}}`, encodeURIComponent(String(requestParameters.passTypeId))).replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDelete(requestParameters: ApiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPostRaw(requestParameters: ApiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPost.');
        }

        if (requestParameters.passTypeId === null || requestParameters.passTypeId === undefined) {
            throw new runtime.RequiredError('passTypeId','Required parameter requestParameters.passTypeId was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPost.');
        }

        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPost.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/DigitalPassport/PassKit/{version}/devices/{deviceId}/registrations/{passTypeId}/{serialNumber}`.replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"passTypeId"}}`, encodeURIComponent(String(requestParameters.passTypeId))).replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters.serialNumber))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppleDeviceRegistrationDtoToJSON(requestParameters.appleDeviceRegistrationDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPost(requestParameters: ApiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDigitalPassportPassKitVersionDevicesDeviceIdRegistrationsPassTypeIdSerialNumberPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGetRaw(requestParameters: ApiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AppleDeviceRosterDto>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGet.');
        }

        if (requestParameters.passTypeId === null || requestParameters.passTypeId === undefined) {
            throw new runtime.RequiredError('passTypeId','Required parameter requestParameters.passTypeId was null or undefined when calling apiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGet.');
        }

        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling apiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['deviceId'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/DigitalPassport/PassKit/{version}/devices/{passTypeId}/{serialNumber}`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"passTypeId"}}`, encodeURIComponent(String(requestParameters.passTypeId))).replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppleDeviceRosterDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGet(requestParameters: ApiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGetRequest, initOverrides?: RequestInit): Promise<AppleDeviceRosterDto> {
        const response = await this.apiDigitalPassportPassKitVersionDevicesPassTypeIdSerialNumberGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGetRaw(requestParameters: ApiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGet.');
        }

        if (requestParameters.passTypeId === null || requestParameters.passTypeId === undefined) {
            throw new runtime.RequiredError('passTypeId','Required parameter requestParameters.passTypeId was null or undefined when calling apiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGet.');
        }

        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling apiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/DigitalPassport/PassKit/{version}/passes/{passTypeId}/{serialNumber}`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))).replace(`{${"passTypeId"}}`, encodeURIComponent(String(requestParameters.passTypeId))).replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGet(requestParameters: ApiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGetRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiDigitalPassportPassKitVersionPassesPassTypeIdSerialNumberGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDigitalPassportResendPostRaw(requestParameters: ApiDigitalPassportResendPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/DigitalPassport/Resend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrusteeStudentMappingDtoToJSON(requestParameters.trusteeStudentMappingDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDigitalPassportResendPost(requestParameters: ApiDigitalPassportResendPostRequest = {}, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiDigitalPassportResendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDigitalPassportTrusteeTrusteeIdGetRaw(requestParameters: ApiDigitalPassportTrusteeTrusteeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DigitalPassportDto>> {
        if (requestParameters.trusteeId === null || requestParameters.trusteeId === undefined) {
            throw new runtime.RequiredError('trusteeId','Required parameter requestParameters.trusteeId was null or undefined when calling apiDigitalPassportTrusteeTrusteeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/DigitalPassport/Trustee/{trusteeId}`.replace(`{${"trusteeId"}}`, encodeURIComponent(String(requestParameters.trusteeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DigitalPassportDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiDigitalPassportTrusteeTrusteeIdGet(requestParameters: ApiDigitalPassportTrusteeTrusteeIdGetRequest, initOverrides?: RequestInit): Promise<DigitalPassportDto> {
        const response = await this.apiDigitalPassportTrusteeTrusteeIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
