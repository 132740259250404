/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentIntentClientSecret
 */
export interface PaymentIntentClientSecret {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentClientSecret
     */
    clientSecret?: string | null;
}

export function PaymentIntentClientSecretFromJSON(json: any): PaymentIntentClientSecret {
    return PaymentIntentClientSecretFromJSONTyped(json, false);
}

export function PaymentIntentClientSecretFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentIntentClientSecret {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
    };
}

export function PaymentIntentClientSecretToJSON(value?: PaymentIntentClientSecret | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientSecret': value.clientSecret,
    };
}

