/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaymentIntentClientSecret,
    PaymentIntentClientSecretFromJSON,
    PaymentIntentClientSecretToJSON,
    PaymentIntentCreateRequest,
    PaymentIntentCreateRequestFromJSON,
    PaymentIntentCreateRequestToJSON,
    StripeLineItemsDto,
    StripeLineItemsDtoFromJSON,
    StripeLineItemsDtoToJSON,
} from '../models';

export interface ApiPaymentIntentCheckoutPostRequest {
    classSessionId?: string;
}

export interface ApiPaymentIntentPostRequest {
    paymentIntentCreateRequest?: PaymentIntentCreateRequest;
}

/**
 * 
 */
export class PaymentIntentApi extends runtime.BaseAPI {

    /**
     */
    async apiPaymentIntentCheckoutPostRaw(requestParameters: ApiPaymentIntentCheckoutPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.classSessionId !== undefined) {
            queryParameters['classSessionId'] = requestParameters.classSessionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/PaymentIntent/checkout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiPaymentIntentCheckoutPost(requestParameters: ApiPaymentIntentCheckoutPostRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiPaymentIntentCheckoutPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPaymentIntentCheckoutWebhookPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/PaymentIntent/checkout-webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPaymentIntentCheckoutWebhookPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiPaymentIntentCheckoutWebhookPostRaw(initOverrides);
    }

    /**
     */
    async apiPaymentIntentPostRaw(requestParameters: ApiPaymentIntentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentIntentClientSecret>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/PaymentIntent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentIntentCreateRequestToJSON(requestParameters.paymentIntentCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentIntentClientSecretFromJSON(jsonValue));
    }

    /**
     */
    async apiPaymentIntentPost(requestParameters: ApiPaymentIntentPostRequest = {}, initOverrides?: RequestInit): Promise<PaymentIntentClientSecret> {
        const response = await this.apiPaymentIntentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPaymentIntentStripeProductsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StripeLineItemsDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/PaymentIntent/StripeProducts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StripeLineItemsDtoFromJSON));
    }

    /**
     */
    async apiPaymentIntentStripeProductsGet(initOverrides?: RequestInit): Promise<Array<StripeLineItemsDto>> {
        const response = await this.apiPaymentIntentStripeProductsGetRaw(initOverrides);
        return await response.value();
    }

}
