/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AllergyDto,
    AllergyDtoFromJSON,
    AllergyDtoToJSON,
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoToJSON,
    TrusteeDto,
    TrusteeDtoFromJSON,
    TrusteeDtoToJSON,
} from '../models';

export interface ApiStudentGetRequest {
    userId?: string;
}

export interface ApiStudentPostRequest {
    studentDto?: StudentDto;
}

export interface ApiStudentStudentIdAllergiesAllergyIdDeleteRequest {
    studentId: string;
    allergyId: string;
}

export interface ApiStudentStudentIdAllergiesPostRequest {
    studentId: string;
    allergyDto?: AllergyDto;
}

export interface ApiStudentStudentIdGetRequest {
    studentId: string;
}

export interface ApiStudentStudentIdTrusteesGetRequest {
    studentId: string;
}

export interface ApiStudentTrusteesPostRequest {
    trusteeDto?: TrusteeDto;
}

export interface ApiStudentTrusteesTrusteeIdDeleteRequest {
    trusteeId: string;
}

export interface ApiStudentTrusteesTrusteeIdGetRequest {
    trusteeId: string;
}

export interface ApiStudentTrusteesTrusteeIdPutRequest {
    trusteeId: string;
    trusteeDto?: TrusteeDto;
}

/**
 * 
 */
export class StudentApi extends runtime.BaseAPI {

    /**
     */
    async apiStudentGetRaw(requestParameters: ApiStudentGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StudentDto>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Student`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StudentDtoFromJSON));
    }

    /**
     */
    async apiStudentGet(requestParameters: ApiStudentGetRequest = {}, initOverrides?: RequestInit): Promise<Array<StudentDto>> {
        const response = await this.apiStudentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentPostRaw(requestParameters: ApiStudentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Student`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StudentDtoToJSON(requestParameters.studentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStudentPost(requestParameters: ApiStudentPostRequest = {}, initOverrides?: RequestInit): Promise<StudentDto> {
        const response = await this.apiStudentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentStudentIdAllergiesAllergyIdDeleteRaw(requestParameters: ApiStudentStudentIdAllergiesAllergyIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling apiStudentStudentIdAllergiesAllergyIdDelete.');
        }

        if (requestParameters.allergyId === null || requestParameters.allergyId === undefined) {
            throw new runtime.RequiredError('allergyId','Required parameter requestParameters.allergyId was null or undefined when calling apiStudentStudentIdAllergiesAllergyIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Student/{studentId}/Allergies/{allergyId}`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"allergyId"}}`, encodeURIComponent(String(requestParameters.allergyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiStudentStudentIdAllergiesAllergyIdDelete(requestParameters: ApiStudentStudentIdAllergiesAllergyIdDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiStudentStudentIdAllergiesAllergyIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentStudentIdAllergiesPostRaw(requestParameters: ApiStudentStudentIdAllergiesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AllergyDto>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling apiStudentStudentIdAllergiesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Student/{studentId}/Allergies`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllergyDtoToJSON(requestParameters.allergyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllergyDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStudentStudentIdAllergiesPost(requestParameters: ApiStudentStudentIdAllergiesPostRequest, initOverrides?: RequestInit): Promise<AllergyDto> {
        const response = await this.apiStudentStudentIdAllergiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentStudentIdGetRaw(requestParameters: ApiStudentStudentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StudentDto>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling apiStudentStudentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Student/{studentId}`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StudentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStudentStudentIdGet(requestParameters: ApiStudentStudentIdGetRequest, initOverrides?: RequestInit): Promise<StudentDto> {
        const response = await this.apiStudentStudentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentStudentIdTrusteesGetRaw(requestParameters: ApiStudentStudentIdTrusteesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TrusteeDto>>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling apiStudentStudentIdTrusteesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Student/{studentId}/Trustees`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrusteeDtoFromJSON));
    }

    /**
     */
    async apiStudentStudentIdTrusteesGet(requestParameters: ApiStudentStudentIdTrusteesGetRequest, initOverrides?: RequestInit): Promise<Array<TrusteeDto>> {
        const response = await this.apiStudentStudentIdTrusteesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentTrusteesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TrusteeDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Student/Trustees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrusteeDtoFromJSON));
    }

    /**
     */
    async apiStudentTrusteesGet(initOverrides?: RequestInit): Promise<Array<TrusteeDto>> {
        const response = await this.apiStudentTrusteesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentTrusteesPostRaw(requestParameters: ApiStudentTrusteesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrusteeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Student/Trustees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TrusteeDtoToJSON(requestParameters.trusteeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrusteeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStudentTrusteesPost(requestParameters: ApiStudentTrusteesPostRequest = {}, initOverrides?: RequestInit): Promise<TrusteeDto> {
        const response = await this.apiStudentTrusteesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentTrusteesTrusteeIdDeleteRaw(requestParameters: ApiStudentTrusteesTrusteeIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.trusteeId === null || requestParameters.trusteeId === undefined) {
            throw new runtime.RequiredError('trusteeId','Required parameter requestParameters.trusteeId was null or undefined when calling apiStudentTrusteesTrusteeIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Student/Trustees/{trusteeId}`.replace(`{${"trusteeId"}}`, encodeURIComponent(String(requestParameters.trusteeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiStudentTrusteesTrusteeIdDelete(requestParameters: ApiStudentTrusteesTrusteeIdDeleteRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiStudentTrusteesTrusteeIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentTrusteesTrusteeIdGetRaw(requestParameters: ApiStudentTrusteesTrusteeIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TrusteeDto>> {
        if (requestParameters.trusteeId === null || requestParameters.trusteeId === undefined) {
            throw new runtime.RequiredError('trusteeId','Required parameter requestParameters.trusteeId was null or undefined when calling apiStudentTrusteesTrusteeIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Student/Trustees/{trusteeId}`.replace(`{${"trusteeId"}}`, encodeURIComponent(String(requestParameters.trusteeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrusteeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStudentTrusteesTrusteeIdGet(requestParameters: ApiStudentTrusteesTrusteeIdGetRequest, initOverrides?: RequestInit): Promise<TrusteeDto> {
        const response = await this.apiStudentTrusteesTrusteeIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStudentTrusteesTrusteeIdPutRaw(requestParameters: ApiStudentTrusteesTrusteeIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.trusteeId === null || requestParameters.trusteeId === undefined) {
            throw new runtime.RequiredError('trusteeId','Required parameter requestParameters.trusteeId was null or undefined when calling apiStudentTrusteesTrusteeIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Student/Trustees/{trusteeId}`.replace(`{${"trusteeId"}}`, encodeURIComponent(String(requestParameters.trusteeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TrusteeDtoToJSON(requestParameters.trusteeDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiStudentTrusteesTrusteeIdPut(requestParameters: ApiStudentTrusteesTrusteeIdPutRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiStudentTrusteesTrusteeIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
