/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttendanceDto,
    AttendanceDtoFromJSON,
    AttendanceDtoToJSON,
    AttendanceRosterDto,
    AttendanceRosterDtoFromJSON,
    AttendanceRosterDtoToJSON,
    AttendanceSelfServiceDto,
    AttendanceSelfServiceDtoFromJSON,
    AttendanceSelfServiceDtoToJSON,
    DigitalPassportDto,
    DigitalPassportDtoFromJSON,
    DigitalPassportDtoToJSON,
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoToJSON,
} from '../models';

export interface ApiAttendanceClassSessionIdWsGetRequest {
    classSessionId: string;
}

export interface ApiAttendanceDayWeekdayWsGetRequest {
    weekday: string;
}

export interface ApiAttendanceProcessOverridePostRequest {
    attendanceSelfServiceDto?: AttendanceSelfServiceDto;
}

export interface ApiAttendanceProcessPostRequest {
    attendanceSelfServiceDto?: AttendanceSelfServiceDto;
}

export interface ApiAttendanceRosterClassSessionIdGetRequest {
    classSessionId: string;
    date?: Date;
}

export interface ApiAttendanceRosterDayWeekdayGetRequest {
    weekday: string;
}

export interface ApiAttendanceScanPostRequest {
    digitalPassportDto?: DigitalPassportDto;
}

export interface ApiAttendanceTestPostRequest {
    attendanceDto?: AttendanceDto;
}

/**
 * 
 */
export class AttendanceApi extends runtime.BaseAPI {

    /**
     */
    async apiAttendanceClassSessionIdWsGetRaw(requestParameters: ApiAttendanceClassSessionIdWsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiAttendanceClassSessionIdWsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Attendance/{classSessionId}/ws`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAttendanceClassSessionIdWsGet(requestParameters: ApiAttendanceClassSessionIdWsGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAttendanceClassSessionIdWsGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAttendanceDayWeekdayWsGetRaw(requestParameters: ApiAttendanceDayWeekdayWsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.weekday === null || requestParameters.weekday === undefined) {
            throw new runtime.RequiredError('weekday','Required parameter requestParameters.weekday was null or undefined when calling apiAttendanceDayWeekdayWsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Attendance/Day/{weekday}/ws`.replace(`{${"weekday"}}`, encodeURIComponent(String(requestParameters.weekday))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAttendanceDayWeekdayWsGet(requestParameters: ApiAttendanceDayWeekdayWsGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAttendanceDayWeekdayWsGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAttendanceProcessOverridePostRaw(requestParameters: ApiAttendanceProcessOverridePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Attendance/ProcessOverride`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttendanceSelfServiceDtoToJSON(requestParameters.attendanceSelfServiceDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAttendanceProcessOverridePost(requestParameters: ApiAttendanceProcessOverridePostRequest = {}, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiAttendanceProcessOverridePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAttendanceProcessPostRaw(requestParameters: ApiAttendanceProcessPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Attendance/Process`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttendanceSelfServiceDtoToJSON(requestParameters.attendanceSelfServiceDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAttendanceProcessPost(requestParameters: ApiAttendanceProcessPostRequest = {}, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiAttendanceProcessPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAttendanceRosterClassSessionIdGetRaw(requestParameters: ApiAttendanceRosterClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AttendanceRosterDto>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiAttendanceRosterClassSessionIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Attendance/Roster/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendanceRosterDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAttendanceRosterClassSessionIdGet(requestParameters: ApiAttendanceRosterClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<AttendanceRosterDto> {
        const response = await this.apiAttendanceRosterClassSessionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAttendanceRosterDayWeekdayGetRaw(requestParameters: ApiAttendanceRosterDayWeekdayGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AttendanceRosterDto>> {
        if (requestParameters.weekday === null || requestParameters.weekday === undefined) {
            throw new runtime.RequiredError('weekday','Required parameter requestParameters.weekday was null or undefined when calling apiAttendanceRosterDayWeekdayGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Attendance/Roster/Day/{weekday}`.replace(`{${"weekday"}}`, encodeURIComponent(String(requestParameters.weekday))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttendanceRosterDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAttendanceRosterDayWeekdayGet(requestParameters: ApiAttendanceRosterDayWeekdayGetRequest, initOverrides?: RequestInit): Promise<AttendanceRosterDto> {
        const response = await this.apiAttendanceRosterDayWeekdayGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAttendanceScanPostRaw(requestParameters: ApiAttendanceScanPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StudentDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Attendance/Scan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DigitalPassportDtoToJSON(requestParameters.digitalPassportDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StudentDtoFromJSON));
    }

    /**
     */
    async apiAttendanceScanPost(requestParameters: ApiAttendanceScanPostRequest = {}, initOverrides?: RequestInit): Promise<Array<StudentDto>> {
        const response = await this.apiAttendanceScanPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAttendanceTestPostRaw(requestParameters: ApiAttendanceTestPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Attendance/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttendanceDtoToJSON(requestParameters.attendanceDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAttendanceTestPost(requestParameters: ApiAttendanceTestPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiAttendanceTestPostRaw(requestParameters, initOverrides);
    }

}
