/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnnouncementDto,
    AnnouncementDtoFromJSON,
    AnnouncementDtoToJSON,
    NewAnnouncementDto,
    NewAnnouncementDtoFromJSON,
    NewAnnouncementDtoToJSON,
} from '../models';

export interface ApiAnnouncementAnnouncementIdGetRequest {
    announcementId: string;
}

export interface ApiAnnouncementClassSessionClassSessionIdGetRequest {
    classSessionId: string;
    fromDate?: string;
}

export interface ApiAnnouncementGetRequest {
    fromDate?: string;
}

export interface ApiAnnouncementPostRequest {
    newAnnouncementDto?: NewAnnouncementDto;
}

/**
 * 
 */
export class AnnouncementApi extends runtime.BaseAPI {

    /**
     */
    async apiAnnouncementAnnouncementIdGetRaw(requestParameters: ApiAnnouncementAnnouncementIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AnnouncementDto>> {
        if (requestParameters.announcementId === null || requestParameters.announcementId === undefined) {
            throw new runtime.RequiredError('announcementId','Required parameter requestParameters.announcementId was null or undefined when calling apiAnnouncementAnnouncementIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Announcement/{announcementId}`.replace(`{${"announcementId"}}`, encodeURIComponent(String(requestParameters.announcementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnouncementDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAnnouncementAnnouncementIdGet(requestParameters: ApiAnnouncementAnnouncementIdGetRequest, initOverrides?: RequestInit): Promise<AnnouncementDto> {
        const response = await this.apiAnnouncementAnnouncementIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnnouncementClassSessionClassSessionIdGetRaw(requestParameters: ApiAnnouncementClassSessionClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AnnouncementDto>>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiAnnouncementClassSessionClassSessionIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Announcement/ClassSession/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AnnouncementDtoFromJSON));
    }

    /**
     */
    async apiAnnouncementClassSessionClassSessionIdGet(requestParameters: ApiAnnouncementClassSessionClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<Array<AnnouncementDto>> {
        const response = await this.apiAnnouncementClassSessionClassSessionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnnouncementGetRaw(requestParameters: ApiAnnouncementGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AnnouncementDto>>> {
        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Announcement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AnnouncementDtoFromJSON));
    }

    /**
     */
    async apiAnnouncementGet(requestParameters: ApiAnnouncementGetRequest = {}, initOverrides?: RequestInit): Promise<Array<AnnouncementDto>> {
        const response = await this.apiAnnouncementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAnnouncementPostRaw(requestParameters: ApiAnnouncementPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Announcement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewAnnouncementDtoToJSON(requestParameters.newAnnouncementDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiAnnouncementPost(requestParameters: ApiAnnouncementPostRequest = {}, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiAnnouncementPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
