/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClassSessionEnrollmentDto,
    ClassSessionEnrollmentDtoFromJSON,
    ClassSessionEnrollmentDtoToJSON,
    ClassSessionWaitlistSubmissionDto,
    ClassSessionWaitlistSubmissionDtoFromJSON,
    ClassSessionWaitlistSubmissionDtoToJSON,
    NewClassSessionEnrollmentDto,
    NewClassSessionEnrollmentDtoFromJSON,
    NewClassSessionEnrollmentDtoToJSON,
    NewClassSessionWaitlistSubmissionDto,
    NewClassSessionWaitlistSubmissionDtoFromJSON,
    NewClassSessionWaitlistSubmissionDtoToJSON,
    PaymentDto,
    PaymentDtoFromJSON,
    PaymentDtoToJSON,
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoToJSON,
} from '../models';

export interface ApiClassSessionEnrollmentClassSessionIdGetRequest {
    classSessionId: string;
}

export interface ApiClassSessionEnrollmentClassSessionIdPostRequest {
    classSessionId: string;
    classSessionEnrollmentDto?: ClassSessionEnrollmentDto;
}

export interface ApiClassSessionEnrollmentClassSessionIdVerifyEligibilityGetRequest {
    classSessionId: string;
}

export interface ApiClassSessionEnrollmentConfirmationCheckoutIdGetRequest {
    checkoutId: string;
}

export interface ApiClassSessionEnrollmentJoinWaitlistClassSessionIdPostRequest {
    classSessionId: string;
    newClassSessionWaitlistSubmissionDto?: NewClassSessionWaitlistSubmissionDto;
}

export interface ApiClassSessionEnrollmentJoinWaitlistOldClassSessionIdPostRequest {
    classSessionId: string;
    newClassSessionEnrollmentDto?: NewClassSessionEnrollmentDto;
}

export interface ApiClassSessionEnrollmentPostRequest {
    newClassSessionEnrollmentDto?: NewClassSessionEnrollmentDto;
}

/**
 * 
 */
export class ClassSessionEnrollmentApi extends runtime.BaseAPI {

    /**
     */
    async apiClassSessionEnrollmentClassSessionIdGetRaw(requestParameters: ApiClassSessionEnrollmentClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StudentDto>>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionEnrollmentClassSessionIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSessionEnrollment/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StudentDtoFromJSON));
    }

    /**
     */
    async apiClassSessionEnrollmentClassSessionIdGet(requestParameters: ApiClassSessionEnrollmentClassSessionIdGetRequest, initOverrides?: RequestInit): Promise<Array<StudentDto>> {
        const response = await this.apiClassSessionEnrollmentClassSessionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionEnrollmentClassSessionIdPostRaw(requestParameters: ApiClassSessionEnrollmentClassSessionIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassSessionEnrollmentDto>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionEnrollmentClassSessionIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ClassSessionEnrollment/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClassSessionEnrollmentDtoToJSON(requestParameters.classSessionEnrollmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassSessionEnrollmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClassSessionEnrollmentClassSessionIdPost(requestParameters: ApiClassSessionEnrollmentClassSessionIdPostRequest, initOverrides?: RequestInit): Promise<ClassSessionEnrollmentDto> {
        const response = await this.apiClassSessionEnrollmentClassSessionIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionEnrollmentClassSessionIdVerifyEligibilityGetRaw(requestParameters: ApiClassSessionEnrollmentClassSessionIdVerifyEligibilityGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionEnrollmentClassSessionIdVerifyEligibilityGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSessionEnrollment/{classSessionId}/VerifyEligibility`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiClassSessionEnrollmentClassSessionIdVerifyEligibilityGet(requestParameters: ApiClassSessionEnrollmentClassSessionIdVerifyEligibilityGetRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiClassSessionEnrollmentClassSessionIdVerifyEligibilityGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionEnrollmentConfirmationCheckoutIdGetRaw(requestParameters: ApiClassSessionEnrollmentConfirmationCheckoutIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentDto>> {
        if (requestParameters.checkoutId === null || requestParameters.checkoutId === undefined) {
            throw new runtime.RequiredError('checkoutId','Required parameter requestParameters.checkoutId was null or undefined when calling apiClassSessionEnrollmentConfirmationCheckoutIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSessionEnrollment/Confirmation/{checkoutId}`.replace(`{${"checkoutId"}}`, encodeURIComponent(String(requestParameters.checkoutId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClassSessionEnrollmentConfirmationCheckoutIdGet(requestParameters: ApiClassSessionEnrollmentConfirmationCheckoutIdGetRequest, initOverrides?: RequestInit): Promise<PaymentDto> {
        const response = await this.apiClassSessionEnrollmentConfirmationCheckoutIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionEnrollmentGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassSessionEnrollmentDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ClassSessionEnrollment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassSessionEnrollmentDtoFromJSON));
    }

    /**
     */
    async apiClassSessionEnrollmentGet(initOverrides?: RequestInit): Promise<Array<ClassSessionEnrollmentDto>> {
        const response = await this.apiClassSessionEnrollmentGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionEnrollmentJoinWaitlistClassSessionIdPostRaw(requestParameters: ApiClassSessionEnrollmentJoinWaitlistClassSessionIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ClassSessionWaitlistSubmissionDto>>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionEnrollmentJoinWaitlistClassSessionIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ClassSessionEnrollment/JoinWaitlist/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewClassSessionWaitlistSubmissionDtoToJSON(requestParameters.newClassSessionWaitlistSubmissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClassSessionWaitlistSubmissionDtoFromJSON));
    }

    /**
     */
    async apiClassSessionEnrollmentJoinWaitlistClassSessionIdPost(requestParameters: ApiClassSessionEnrollmentJoinWaitlistClassSessionIdPostRequest, initOverrides?: RequestInit): Promise<Array<ClassSessionWaitlistSubmissionDto>> {
        const response = await this.apiClassSessionEnrollmentJoinWaitlistClassSessionIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionEnrollmentJoinWaitlistOldClassSessionIdPostRaw(requestParameters: ApiClassSessionEnrollmentJoinWaitlistOldClassSessionIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClassSessionEnrollmentDto>> {
        if (requestParameters.classSessionId === null || requestParameters.classSessionId === undefined) {
            throw new runtime.RequiredError('classSessionId','Required parameter requestParameters.classSessionId was null or undefined when calling apiClassSessionEnrollmentJoinWaitlistOldClassSessionIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ClassSessionEnrollment/JoinWaitlistOld/{classSessionId}`.replace(`{${"classSessionId"}}`, encodeURIComponent(String(requestParameters.classSessionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewClassSessionEnrollmentDtoToJSON(requestParameters.newClassSessionEnrollmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClassSessionEnrollmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClassSessionEnrollmentJoinWaitlistOldClassSessionIdPost(requestParameters: ApiClassSessionEnrollmentJoinWaitlistOldClassSessionIdPostRequest, initOverrides?: RequestInit): Promise<ClassSessionEnrollmentDto> {
        const response = await this.apiClassSessionEnrollmentJoinWaitlistOldClassSessionIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiClassSessionEnrollmentPostRaw(requestParameters: ApiClassSessionEnrollmentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ClassSessionEnrollment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewClassSessionEnrollmentDtoToJSON(requestParameters.newClassSessionEnrollmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiClassSessionEnrollmentPost(requestParameters: ApiClassSessionEnrollmentPostRequest = {}, initOverrides?: RequestInit): Promise<PaymentDto> {
        const response = await this.apiClassSessionEnrollmentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
