/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnnouncementDto,
    AnnouncementDtoFromJSON,
    AnnouncementDtoFromJSONTyped,
    AnnouncementDtoToJSON,
} from './AnnouncementDto';
import {
    ClassPolicy,
    ClassPolicyFromJSON,
    ClassPolicyFromJSONTyped,
    ClassPolicyToJSON,
} from './ClassPolicy';
import {
    ClassSectionDto,
    ClassSectionDtoFromJSON,
    ClassSectionDtoFromJSONTyped,
    ClassSectionDtoToJSON,
} from './ClassSectionDto';
import {
    ClassSessionEnrollmentDto,
    ClassSessionEnrollmentDtoFromJSON,
    ClassSessionEnrollmentDtoFromJSONTyped,
    ClassSessionEnrollmentDtoToJSON,
} from './ClassSessionEnrollmentDto';
import {
    ClassSessionWaitlistSubmissionDto,
    ClassSessionWaitlistSubmissionDtoFromJSON,
    ClassSessionWaitlistSubmissionDtoFromJSONTyped,
    ClassSessionWaitlistSubmissionDtoToJSON,
} from './ClassSessionWaitlistSubmissionDto';
import {
    DigitalResourceDto,
    DigitalResourceDtoFromJSON,
    DigitalResourceDtoFromJSONTyped,
    DigitalResourceDtoToJSON,
} from './DigitalResourceDto';

/**
 * 
 * @export
 * @interface ClassSessionDto
 */
export interface ClassSessionDto {
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    thumbnail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionDto
     */
    sessionStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionDto
     */
    sessionEndDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionDto
     */
    priorityRegistrationStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionDto
     */
    priorityRegistrationEndDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionDto
     */
    registrationStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionDto
     */
    registrationEndDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    daySchedule?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassSessionDto
     */
    isActive?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    stripeLineItem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    supplyFeeLineItem?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassSessionDto
     */
    registrationPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassSessionDto
     */
    supplyFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassSessionDto
     */
    rosterSize?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassSessionDto
     */
    waitlistActive?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ClassSessionDto
     */
    waitlistSize?: number | null;
    /**
     * 
     * @type {Array<ClassSessionEnrollmentDto>}
     * @memberof ClassSessionDto
     */
    classSessionEnrollments?: Array<ClassSessionEnrollmentDto> | null;
    /**
     * 
     * @type {Array<ClassSessionWaitlistSubmissionDto>}
     * @memberof ClassSessionDto
     */
    classSessionWaitlistSubmissions?: Array<ClassSessionWaitlistSubmissionDto> | null;
    /**
     * 
     * @type {Array<DigitalResourceDto>}
     * @memberof ClassSessionDto
     */
    digitalResources?: Array<DigitalResourceDto> | null;
    /**
     * 
     * @type {Array<AnnouncementDto>}
     * @memberof ClassSessionDto
     */
    announcements?: Array<AnnouncementDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ClassSessionDto
     */
    classSectionId?: string | null;
    /**
     * 
     * @type {ClassSectionDto}
     * @memberof ClassSessionDto
     */
    classSection?: ClassSectionDto;
    /**
     * 
     * @type {ClassPolicy}
     * @memberof ClassSessionDto
     */
    expectationPolicy?: ClassPolicy;
    /**
     * 
     * @type {ClassPolicy}
     * @memberof ClassSessionDto
     */
    dismissalPolicy?: ClassPolicy;
    /**
     * 
     * @type {ClassPolicy}
     * @memberof ClassSessionDto
     */
    latePickupPolicy?: ClassPolicy;
}

export function ClassSessionDtoFromJSON(json: any): ClassSessionDto {
    return ClassSessionDtoFromJSONTyped(json, false);
}

export function ClassSessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSessionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'sessionStartDate': !exists(json, 'sessionStartDate') ? undefined : (json['sessionStartDate'] === null ? null : new Date(json['sessionStartDate'])),
        'sessionEndDate': !exists(json, 'sessionEndDate') ? undefined : (json['sessionEndDate'] === null ? null : new Date(json['sessionEndDate'])),
        'priorityRegistrationStartDate': !exists(json, 'priorityRegistrationStartDate') ? undefined : (json['priorityRegistrationStartDate'] === null ? null : new Date(json['priorityRegistrationStartDate'])),
        'priorityRegistrationEndDate': !exists(json, 'priorityRegistrationEndDate') ? undefined : (json['priorityRegistrationEndDate'] === null ? null : new Date(json['priorityRegistrationEndDate'])),
        'registrationStartDate': !exists(json, 'registrationStartDate') ? undefined : (json['registrationStartDate'] === null ? null : new Date(json['registrationStartDate'])),
        'registrationEndDate': !exists(json, 'registrationEndDate') ? undefined : (json['registrationEndDate'] === null ? null : new Date(json['registrationEndDate'])),
        'daySchedule': !exists(json, 'daySchedule') ? undefined : json['daySchedule'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'stripeLineItem': !exists(json, 'stripeLineItem') ? undefined : json['stripeLineItem'],
        'supplyFeeLineItem': !exists(json, 'supplyFeeLineItem') ? undefined : json['supplyFeeLineItem'],
        'registrationPrice': !exists(json, 'registrationPrice') ? undefined : json['registrationPrice'],
        'supplyFee': !exists(json, 'supplyFee') ? undefined : json['supplyFee'],
        'rosterSize': !exists(json, 'rosterSize') ? undefined : json['rosterSize'],
        'waitlistActive': !exists(json, 'waitlistActive') ? undefined : json['waitlistActive'],
        'waitlistSize': !exists(json, 'waitlistSize') ? undefined : json['waitlistSize'],
        'classSessionEnrollments': !exists(json, 'classSessionEnrollments') ? undefined : (json['classSessionEnrollments'] === null ? null : (json['classSessionEnrollments'] as Array<any>).map(ClassSessionEnrollmentDtoFromJSON)),
        'classSessionWaitlistSubmissions': !exists(json, 'classSessionWaitlistSubmissions') ? undefined : (json['classSessionWaitlistSubmissions'] === null ? null : (json['classSessionWaitlistSubmissions'] as Array<any>).map(ClassSessionWaitlistSubmissionDtoFromJSON)),
        'digitalResources': !exists(json, 'digitalResources') ? undefined : (json['digitalResources'] === null ? null : (json['digitalResources'] as Array<any>).map(DigitalResourceDtoFromJSON)),
        'announcements': !exists(json, 'announcements') ? undefined : (json['announcements'] === null ? null : (json['announcements'] as Array<any>).map(AnnouncementDtoFromJSON)),
        'classSectionId': !exists(json, 'classSectionId') ? undefined : json['classSectionId'],
        'classSection': !exists(json, 'classSection') ? undefined : ClassSectionDtoFromJSON(json['classSection']),
        'expectationPolicy': !exists(json, 'expectationPolicy') ? undefined : ClassPolicyFromJSON(json['expectationPolicy']),
        'dismissalPolicy': !exists(json, 'dismissalPolicy') ? undefined : ClassPolicyFromJSON(json['dismissalPolicy']),
        'latePickupPolicy': !exists(json, 'latePickupPolicy') ? undefined : ClassPolicyFromJSON(json['latePickupPolicy']),
    };
}

export function ClassSessionDtoToJSON(value?: ClassSessionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'thumbnail': value.thumbnail,
        'sessionStartDate': value.sessionStartDate === undefined ? undefined : (value.sessionStartDate === null ? null : value.sessionStartDate.toISOString()),
        'sessionEndDate': value.sessionEndDate === undefined ? undefined : (value.sessionEndDate === null ? null : value.sessionEndDate.toISOString()),
        'priorityRegistrationStartDate': value.priorityRegistrationStartDate === undefined ? undefined : (value.priorityRegistrationStartDate === null ? null : value.priorityRegistrationStartDate.toISOString()),
        'priorityRegistrationEndDate': value.priorityRegistrationEndDate === undefined ? undefined : (value.priorityRegistrationEndDate === null ? null : value.priorityRegistrationEndDate.toISOString()),
        'registrationStartDate': value.registrationStartDate === undefined ? undefined : (value.registrationStartDate === null ? null : value.registrationStartDate.toISOString()),
        'registrationEndDate': value.registrationEndDate === undefined ? undefined : (value.registrationEndDate === null ? null : value.registrationEndDate.toISOString()),
        'daySchedule': value.daySchedule,
        'isActive': value.isActive,
        'stripeLineItem': value.stripeLineItem,
        'supplyFeeLineItem': value.supplyFeeLineItem,
        'registrationPrice': value.registrationPrice,
        'supplyFee': value.supplyFee,
        'rosterSize': value.rosterSize,
        'waitlistActive': value.waitlistActive,
        'waitlistSize': value.waitlistSize,
        'classSessionEnrollments': value.classSessionEnrollments === undefined ? undefined : (value.classSessionEnrollments === null ? null : (value.classSessionEnrollments as Array<any>).map(ClassSessionEnrollmentDtoToJSON)),
        'classSessionWaitlistSubmissions': value.classSessionWaitlistSubmissions === undefined ? undefined : (value.classSessionWaitlistSubmissions === null ? null : (value.classSessionWaitlistSubmissions as Array<any>).map(ClassSessionWaitlistSubmissionDtoToJSON)),
        'digitalResources': value.digitalResources === undefined ? undefined : (value.digitalResources === null ? null : (value.digitalResources as Array<any>).map(DigitalResourceDtoToJSON)),
        'announcements': value.announcements === undefined ? undefined : (value.announcements === null ? null : (value.announcements as Array<any>).map(AnnouncementDtoToJSON)),
        'classSectionId': value.classSectionId,
        'classSection': ClassSectionDtoToJSON(value.classSection),
        'expectationPolicy': ClassPolicyToJSON(value.expectationPolicy),
        'dismissalPolicy': ClassPolicyToJSON(value.dismissalPolicy),
        'latePickupPolicy': ClassPolicyToJSON(value.latePickupPolicy),
    };
}

