/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigitalPassportTrusteeMapping,
    DigitalPassportTrusteeMappingFromJSON,
    DigitalPassportTrusteeMappingFromJSONTyped,
    DigitalPassportTrusteeMappingToJSON,
} from './DigitalPassportTrusteeMapping';
import {
    TrusteeStudentMapping,
    TrusteeStudentMappingFromJSON,
    TrusteeStudentMappingFromJSONTyped,
    TrusteeStudentMappingToJSON,
} from './TrusteeStudentMapping';
import {
    TrusteeUserMapping,
    TrusteeUserMappingFromJSON,
    TrusteeUserMappingFromJSONTyped,
    TrusteeUserMappingToJSON,
} from './TrusteeUserMapping';

/**
 * 
 * @export
 * @interface Trustee
 */
export interface Trustee {
    /**
     * 
     * @type {string}
     * @memberof Trustee
     */
    id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Trustee
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Trustee
     */
    type?: number;
    /**
     * 
     * @type {string}
     * @memberof Trustee
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trustee
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trustee
     */
    readonly fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trustee
     */
    homePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trustee
     */
    cellPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Trustee
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Trustee
     */
    relationship?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Trustee
     */
    canPickup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Trustee
     */
    receiveSMS?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Trustee
     */
    receiveEmail?: boolean;
    /**
     * 
     * @type {Array<DigitalPassportTrusteeMapping>}
     * @memberof Trustee
     */
    digitalPassportTrusteeMappings?: Array<DigitalPassportTrusteeMapping> | null;
    /**
     * 
     * @type {Array<TrusteeUserMapping>}
     * @memberof Trustee
     */
    trusteeUserMappings?: Array<TrusteeUserMapping> | null;
    /**
     * 
     * @type {Array<TrusteeStudentMapping>}
     * @memberof Trustee
     */
    trusteeStudentMappings?: Array<TrusteeStudentMapping> | null;
}

export function TrusteeFromJSON(json: any): Trustee {
    return TrusteeFromJSONTyped(json, false);
}

export function TrusteeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trustee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'homePhone': !exists(json, 'homePhone') ? undefined : json['homePhone'],
        'cellPhone': !exists(json, 'cellPhone') ? undefined : json['cellPhone'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
        'canPickup': !exists(json, 'canPickup') ? undefined : json['canPickup'],
        'receiveSMS': !exists(json, 'receiveSMS') ? undefined : json['receiveSMS'],
        'receiveEmail': !exists(json, 'receiveEmail') ? undefined : json['receiveEmail'],
        'digitalPassportTrusteeMappings': !exists(json, 'digitalPassportTrusteeMappings') ? undefined : (json['digitalPassportTrusteeMappings'] === null ? null : (json['digitalPassportTrusteeMappings'] as Array<any>).map(DigitalPassportTrusteeMappingFromJSON)),
        'trusteeUserMappings': !exists(json, 'trusteeUserMappings') ? undefined : (json['trusteeUserMappings'] === null ? null : (json['trusteeUserMappings'] as Array<any>).map(TrusteeUserMappingFromJSON)),
        'trusteeStudentMappings': !exists(json, 'trusteeStudentMappings') ? undefined : (json['trusteeStudentMappings'] === null ? null : (json['trusteeStudentMappings'] as Array<any>).map(TrusteeStudentMappingFromJSON)),
    };
}

export function TrusteeToJSON(value?: Trustee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'type': value.type,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'homePhone': value.homePhone,
        'cellPhone': value.cellPhone,
        'emailAddress': value.emailAddress,
        'relationship': value.relationship,
        'canPickup': value.canPickup,
        'receiveSMS': value.receiveSMS,
        'receiveEmail': value.receiveEmail,
        'digitalPassportTrusteeMappings': value.digitalPassportTrusteeMappings === undefined ? undefined : (value.digitalPassportTrusteeMappings === null ? null : (value.digitalPassportTrusteeMappings as Array<any>).map(DigitalPassportTrusteeMappingToJSON)),
        'trusteeUserMappings': value.trusteeUserMappings === undefined ? undefined : (value.trusteeUserMappings === null ? null : (value.trusteeUserMappings as Array<any>).map(TrusteeUserMappingToJSON)),
        'trusteeStudentMappings': value.trusteeStudentMappings === undefined ? undefined : (value.trusteeStudentMappings === null ? null : (value.trusteeStudentMappings as Array<any>).map(TrusteeStudentMappingToJSON)),
    };
}

