import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import { Box, Divider, Typography } from "@mui/material";
import Main from "layouts/Main";
import Container from "Components/Container";
import {
  Hero,
  OurPhilosophyPreview,
  PagesOverview,
  OurPrograms,
  TestimonialPreview,
  PhotoGrid,
  SocialMedia,
} from "./components";
import Contact from "Components/Contact";
import HeroFullWidth from "Components/HeroFullWidth";

const IndexView = () => {
  const theme = useTheme();
  return (
    <>
      {/*<Box sx={{ overflowX: "hidden" }}>
  <Main bgcolor={"background.paper"}>*/}
      <HeroFullWidth
        title="A one-of-a-kind learning center"
        subtitle="Now accepting new students"
        bgcolor={theme.palette.background.blue1}
        imgtype="bleed"
        imgsrc="/static/images/cups-home.png"
        imgpos="right"
        buttontext="Learn about our philosophy"
        buttonvariant="yellow"
      />
      <Container maxWidth={1350}>
        <PagesOverview />
      </Container>
      <Box
        sx={{
          backgroundImage: "url(/static/images/arches.png)",
          backgroundPosition: "right bottom",
          backgroundSize: { xs: "20%", sm: "10%" },
          backgroundRepeat: "no-repeat",
          pb: { xs: 1, sm: 5 },
        }}
      >
        <Container>
          <OurPhilosophyPreview />
        </Container>
      </Box>
      <Container>
        <PhotoGrid />
      </Container>
      <Container maxWidth={980}>
        <OurPrograms />
      </Container>
      <Container>
        <TestimonialPreview />
      </Container>
      {/*<Box
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center 30%",
          backgroundImage: "url(/static/images/laughing_kids.webp)",
          height: 600,
        }}
      ></Box>*/}
      <Divider />
      <Box>
        <Contact />
      </Box>
      <Container>
        <SocialMedia />
      </Container>
      {/*</Main>
    </Box>*/}
    </>
  );
};

export default IndexView;
