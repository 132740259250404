import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Container from "Components/Container";

const mock = [
  {
    label: "Email",
    value: (
      <>
        <a
          href="mailto:hello@bloomlearngrow.com"
          style={{
            textDecoration: "none",
            "&:link": { color: "#1e2022" },
            "&:visited": { color: "#1e2022" },
            "&": { color: "#1e2022" },
            color: "#1e2022",
          }}
        >
          hello@bloomlearngrow.com
        </a>
      </>
    ),
    color: "#FE7295",
  },
  {
    label: "Address",
    value: (
      <>
        3170 Chino Ave. Suite C<br />
        Chino Hills, CA 91709
      </>
    ),
    color: "#9ec95c",
  },
  {
    label: "Phone",
    value: "(909) 293-8176",
    color: "#4ea3ec",
  },
];

const Contact = () => {
  const theme = useTheme();

  const LeftSide = () => {
    return (
      <Box>
        <Box marginBottom={2}>
          <Typography variant={"h3"} sx={{ fontWeight: 700 }} gutterBottom>
            Contact us
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
        >
          {mock.map((item, i) => (
            <Box
              key={i}
              component={ListItem}
              disableGutters
              width={"auto"}
              padding={0}
            >
              <Box
                component={ListItemAvatar}
                minWidth={"auto !important"}
                marginRight={2}
                marginBottom={1}
              >
                <Box
                  justifyContent="center"
                  color={item.color}
                  height={40}
                  sx={{
                    mb: { xs: 1, sm: 0 },
                    mt: { xs: 1, sm: 2 },
                    fontSize: "50px",
                    fontWeight: "400",
                    lineHeight: "50px",
                  }}
                >
                  *
                </Box>
              </Box>
              <ListItemText
                sx={{ fontSize: "25px", fontWeight: "400", lineHeight: "32px" }}
                primary={item.value}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const RightSide = () => {
    return (
      <Box
        sx={{
          borderRadius: "16px",
          overflow: "hidden",
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d875.9828949489624!2d-117.73784246705084!3d34.011266681538075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c333d5f11e5083%3A0x401cb48fbf9a61d2!2sBloom!5e0!3m2!1sen!2sus!4v1656284155668!5m2!1sen!2sus"
          width="100%"
          height="100%"
          frameBorder="0"
          style="border:0;"
          allowfullscreen=""
          title="map"
          marginHeight={0}
          marginWidth={0}
          scrolling="no"
          style={{
            minHeight: 300,
            filter:
              theme.palette.mode === "dark"
                ? "grayscale(0.5) opacity(0.7)"
                : "none",
          }}
        ></iframe>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          position={"relative"}
        >
          <Box width={1} order={{ xs: 2, md: 1 }} sx={{ mt: 5, mb: 5 }}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <LeftSide />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RightSide />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default Contact;
