/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSessionDto,
    ClassSessionDtoFromJSON,
    ClassSessionDtoFromJSONTyped,
    ClassSessionDtoToJSON,
} from './ClassSessionDto';
import {
    PaymentDto,
    PaymentDtoFromJSON,
    PaymentDtoFromJSONTyped,
    PaymentDtoToJSON,
} from './PaymentDto';
import {
    StudentDto,
    StudentDtoFromJSON,
    StudentDtoFromJSONTyped,
    StudentDtoToJSON,
} from './StudentDto';

/**
 * 
 * @export
 * @interface ClassSessionEnrollmentDto
 */
export interface ClassSessionEnrollmentDto {
    /**
     * 
     * @type {string}
     * @memberof ClassSessionEnrollmentDto
     */
    id?: string | null;
    /**
     * 
     * @type {ClassSessionDto}
     * @memberof ClassSessionEnrollmentDto
     */
    classSession?: ClassSessionDto;
    /**
     * 
     * @type {StudentDto}
     * @memberof ClassSessionEnrollmentDto
     */
    student?: StudentDto;
    /**
     * 
     * @type {Array<StudentDto>}
     * @memberof ClassSessionEnrollmentDto
     */
    students?: Array<StudentDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassSessionEnrollmentDto
     */
    status?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassSessionEnrollmentDto
     */
    created?: Date | null;
    /**
     * 
     * @type {PaymentDto}
     * @memberof ClassSessionEnrollmentDto
     */
    payment?: PaymentDto;
}

export function ClassSessionEnrollmentDtoFromJSON(json: any): ClassSessionEnrollmentDto {
    return ClassSessionEnrollmentDtoFromJSONTyped(json, false);
}

export function ClassSessionEnrollmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSessionEnrollmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'classSession': !exists(json, 'classSession') ? undefined : ClassSessionDtoFromJSON(json['classSession']),
        'student': !exists(json, 'student') ? undefined : StudentDtoFromJSON(json['student']),
        'students': !exists(json, 'students') ? undefined : (json['students'] === null ? null : (json['students'] as Array<any>).map(StudentDtoFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : (json['created'] === null ? null : new Date(json['created'])),
        'payment': !exists(json, 'payment') ? undefined : PaymentDtoFromJSON(json['payment']),
    };
}

export function ClassSessionEnrollmentDtoToJSON(value?: ClassSessionEnrollmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'classSession': ClassSessionDtoToJSON(value.classSession),
        'student': StudentDtoToJSON(value.student),
        'students': value.students === undefined ? undefined : (value.students === null ? null : (value.students as Array<any>).map(StudentDtoToJSON)),
        'status': value.status,
        'created': value.created === undefined ? undefined : (value.created === null ? null : value.created.toISOString()),
        'payment': PaymentDtoToJSON(value.payment),
    };
}

