import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light, dark } from "./palette";

const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === "light" ? light : dark,
      shadows: shadows(mode),
      typography: {
        fontFamily: '"Apercu", sans-serif',
        button: {
          textTransform: "none",
          fontWeight: "medium",
        },
        subtitle3: {
          fontWeight: 400,
          fontSize: "14px",
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: "0 0",
              borderTop: "1px solid #00000080",
              paddingBottom: 30,
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            content: {
              fontSize: "30px",
              fontWeight: 700,
            },
          },
        },
        MuiTypography: {
          variants: [
            {
              props: { variant: "h4-blue" },
              style: {
                color: "#5caaee",
                fontWeight: "400",
                fontSize: "2rem",
                lineHeight: "1.167",
                letterSpacng: "0.00735em",
              },
            },
            {
              props: { variant: "h4-white" },
              style: {
                color: "#fff",
                fontWeight: "400",
                fontSize: "2rem",
                lineHeight: "1.167",
                letterSpacng: "0.00735em",
              },
            },
            {
              props: { variant: "h6-blue" },
              style: {
                color: "#5caaee",
                fontWeight: "500",
                fontSize: "1.25rem",
                lineHeight: "1.6",
                letterSpacng: "0.0075em",
              },
            },
            {
              props: { variant: "h6-white" },
              style: {
                color: "#fff",
                fontWeight: "400",
                fontSize: "1.25rem",
                lineHeight: "1.6",
                letterSpacng: "0.0075em",
                align: "center",
              },
            },
          ],
        },
        MuiButton: {
          variants: [
            {
              props: { variant: "red" },
              style: {
                backgroundColor: "#ec5a53",
                fontWeight: 700,
                borderRadius: 30,
                paddingLeft: 30,
                paddingRight: 30,
                color: "#fff",
                ":hover": {
                  backgroundColor: "#c64c46",
                },
              },
            },
            {
              props: { variant: "yellow" },
              style: {
                backgroundColor: "#ffce41",
                fontWeight: 700,
                borderRadius: 30,
                paddingLeft: 30,
                paddingRight: 30,
                color: "#000",
                ":hover": {
                  backgroundColor: "#f3b032",
                },
              },
            },
            {
              props: { variant: "green" },
              style: {
                backgroundColor: "#97c154",
                fontWeight: 700,
                borderRadius: 30,
                paddingLeft: 30,
                paddingRight: 30,
                color: "#fff",
                ":hover": {
                  backgroundColor: "#80a43e",
                },
              },
            },
            {
              props: { variant: "blue" },
              style: {
                backgroundColor: "#4ea3ec",
                fontWeight: 700,
                borderRadius: 30,
                paddingLeft: 30,
                paddingRight: 30,
                color: "#fff",
                ":hover": {
                  backgroundColor: "#2f6ad9",
                },
              },
            },
          ],
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              //paddingBottom: 10,
              paddingBottom: 5,
            },
            containedSecondary: mode === "light" ? { color: "white" } : {},
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
              backgroundColor: "rgba(124, 113, 105, 0.07)",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 15,
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
            input: {
              borderRadius: 5,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {},
          },
        },
      },
      themeToggler,
    })
  );

export default getTheme;
