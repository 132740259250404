/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoToJSON,
} from '../models';

export interface ApiUserAuthenticationLoginPostRequest {
    userDto?: UserDto;
}

export interface ApiUserAuthenticationRegisterPostRequest {
    userDto?: UserDto;
}

export interface ApiUserAuthenticationResetPasswordPostRequest {
    userDto?: UserDto;
}

export interface ApiUserAuthenticationResetPasswordTokenPostRequest {
    token: string;
    userDto?: UserDto;
}

/**
 * 
 */
export class UserAuthenticationApi extends runtime.BaseAPI {

    /**
     */
    async apiUserAuthenticationLoginPostRaw(requestParameters: ApiUserAuthenticationLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/UserAuthentication/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserAuthenticationLoginPost(requestParameters: ApiUserAuthenticationLoginPostRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiUserAuthenticationLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserAuthenticationRegisterPostRaw(requestParameters: ApiUserAuthenticationRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/UserAuthentication/Register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserAuthenticationRegisterPost(requestParameters: ApiUserAuthenticationRegisterPostRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiUserAuthenticationRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserAuthenticationResetPasswordPostRaw(requestParameters: ApiUserAuthenticationResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/UserAuthentication/ResetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserAuthenticationResetPasswordPost(requestParameters: ApiUserAuthenticationResetPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiUserAuthenticationResetPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserAuthenticationResetPasswordTokenPostRaw(requestParameters: ApiUserAuthenticationResetPasswordTokenPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling apiUserAuthenticationResetPasswordTokenPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/UserAuthentication/ResetPassword/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiUserAuthenticationResetPasswordTokenPost(requestParameters: ApiUserAuthenticationResetPasswordTokenPostRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.apiUserAuthenticationResetPasswordTokenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
