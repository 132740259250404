/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Trustee,
    TrusteeFromJSON,
    TrusteeFromJSONTyped,
    TrusteeToJSON,
} from './Trustee';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface TrusteeUserMapping
 */
export interface TrusteeUserMapping {
    /**
     * 
     * @type {string}
     * @memberof TrusteeUserMapping
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrusteeUserMapping
     */
    trusteeId?: string | null;
    /**
     * 
     * @type {Trustee}
     * @memberof TrusteeUserMapping
     */
    trustee?: Trustee;
    /**
     * 
     * @type {string}
     * @memberof TrusteeUserMapping
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof TrusteeUserMapping
     */
    user?: User;
}

export function TrusteeUserMappingFromJSON(json: any): TrusteeUserMapping {
    return TrusteeUserMappingFromJSONTyped(json, false);
}

export function TrusteeUserMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrusteeUserMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'trusteeId': !exists(json, 'trusteeId') ? undefined : json['trusteeId'],
        'trustee': !exists(json, 'trustee') ? undefined : TrusteeFromJSON(json['trustee']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function TrusteeUserMappingToJSON(value?: TrusteeUserMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'trusteeId': value.trusteeId,
        'trustee': TrusteeToJSON(value.trustee),
        'userId': value.userId,
        'user': UserToJSON(value.user),
    };
}

