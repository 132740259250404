import React from "react";
import { Grid, Box, Button, Link, Typography } from "@mui/material";
import { Link as Link2 } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box
            display={"flex"}
            component="a"
            href="/"
            title="theFront"
            width={80}
          ></Box>
          <Box display="flex" flexWrap={"wrap"} alignItems={"center"}>
            <Box marginTop={1} marginRight={3}>
              <Link
                underline="none"
                component={Link2}
                to="/"
                color="text.white"
                variant={"subtitle3"}
              >
                Home
              </Link>
            </Box>
            <Box marginTop={1} marginRight={3}>
              <Link
                underline="none"
                component={Link2}
                to="/"
                color="text.white"
                variant={"subtitle3"}
              >
                Our Philosophy
              </Link>
            </Box>
            <Box marginTop={1} marginRight={3}>
              <Link
                underline="none"
                component={Link2}
                to="/"
                color="text.white"
                variant={"subtitle3"}
              >
                Testimonials
              </Link>
            </Box>
            <Box marginTop={1} marginRight={3}>
              <Link
                underline="none"
                component={Link2}
                to="/contact-us"
                color="text.white"
                variant={"subtitle3"}
              >
                FAQ
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component={Link2}
                to="/contact-us"
                color="text.white"
                variant={"subtitle2"}
              >
                Contact Us
              </Link>
            </Box>
            {/*<Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component={Link2}
                to="/terms-and-conditions"
                color="text.primary"
                variant={"subtitle2"}
              >
                Terms & Conditions
              </Link>
            </Box>*/}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: 8 }}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.white"
          gutterBottom
        >
          &copy; Bloom 2024. All rights reserved
        </Typography>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.white"
          component={"p"}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
