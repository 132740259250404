/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import { Avatar, Box, Typography, Grid, Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link as Link2 } from "react-router-dom";

const OurPhilosophyPreview = () => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              paddingLeft: { xs: 1, sm: 1, md: "30%" },
            }}
          >
            <Box
              component={LazyLoadImage}
              effect="blur"
              src={"/static/images/philosophy_index.jpg"}
              height={{ xs: "auto", md: "auto" }}
              width={{ xs: "auto", md: "auto" }}
              maxHeight={{ xs: "auto", md: 500 }}
              maxWidth={{ xs: "100%", md: "auto" }}
              sx={{
                filter:
                  theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                borderRadius: "0% 40% 0% 0%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              paddingRight: { xs: 1, sm: 1, md: "45%" },
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              Our Philosophy
            </Typography>
            <Typography variant="h6">
              When children are provided with a caring environment that is safe,
              organized, filled with engaging toys and activites, they will{" "}
              <Box fontWeight={600} fontStyle={"italic"} display="inline">
                bloom
              </Box>
              .
            </Typography>
            <Box sx={{ mt: 5 }}>
              <Button component={Link2} to={"/our-philosophy"} variant="red">
                Learn More
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurPhilosophyPreview;
