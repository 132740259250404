/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContactFormDto,
    ContactFormDtoFromJSON,
    ContactFormDtoToJSON,
    WaitlistSubmissionDto,
    WaitlistSubmissionDtoFromJSON,
    WaitlistSubmissionDtoToJSON,
} from '../models';

export interface ApiContactPostRequest {
    contactFormDto?: ContactFormDto;
}

export interface ApiContactWaitlistPostRequest {
    waitlistSubmissionDto?: WaitlistSubmissionDto;
}

/**
 * 
 */
export class ContactApi extends runtime.BaseAPI {

    /**
     */
    async apiContactPostRaw(requestParameters: ApiContactPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactFormDtoToJSON(requestParameters.contactFormDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiContactPost(requestParameters: ApiContactPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiContactPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiContactWaitlistPostRaw(requestParameters: ApiContactWaitlistPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Contact/Waitlist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaitlistSubmissionDtoToJSON(requestParameters.waitlistSubmissionDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiContactWaitlistPost(requestParameters: ApiContactWaitlistPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiContactWaitlistPostRaw(requestParameters, initOverrides);
    }

}
