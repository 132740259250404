/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Student,
    StudentFromJSON,
    StudentFromJSONTyped,
    StudentToJSON,
} from './Student';
import {
    Trustee,
    TrusteeFromJSON,
    TrusteeFromJSONTyped,
    TrusteeToJSON,
} from './Trustee';

/**
 * 
 * @export
 * @interface TrusteeStudentMapping
 */
export interface TrusteeStudentMapping {
    /**
     * 
     * @type {string}
     * @memberof TrusteeStudentMapping
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrusteeStudentMapping
     */
    trusteeId?: string | null;
    /**
     * 
     * @type {Trustee}
     * @memberof TrusteeStudentMapping
     */
    trustee?: Trustee;
    /**
     * 
     * @type {string}
     * @memberof TrusteeStudentMapping
     */
    studentId?: string | null;
    /**
     * 
     * @type {Student}
     * @memberof TrusteeStudentMapping
     */
    student?: Student;
}

export function TrusteeStudentMappingFromJSON(json: any): TrusteeStudentMapping {
    return TrusteeStudentMappingFromJSONTyped(json, false);
}

export function TrusteeStudentMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrusteeStudentMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'trusteeId': !exists(json, 'trusteeId') ? undefined : json['trusteeId'],
        'trustee': !exists(json, 'trustee') ? undefined : TrusteeFromJSON(json['trustee']),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'student': !exists(json, 'student') ? undefined : StudentFromJSON(json['student']),
    };
}

export function TrusteeStudentMappingToJSON(value?: TrusteeStudentMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'trusteeId': value.trusteeId,
        'trustee': TrusteeToJSON(value.trustee),
        'studentId': value.studentId,
        'student': StudentToJSON(value.student),
    };
}

