import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./theme/overrides.css";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./Utilities/Auth";
import { SnackbarProvider } from "notistack";
import Slide from "@mui/material/Slide";
import App from "./App";

import Page from "Components/Page";

ReactDOM.render(
  <React.StrictMode>
    <Page>
      <AuthProvider>
        <SnackbarProvider
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={Slide}
        >
          <CssBaseline />
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </Page>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
