/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClassSessionDto,
    ClassSessionDtoFromJSON,
    ClassSessionDtoFromJSONTyped,
    ClassSessionDtoToJSON,
} from './ClassSessionDto';

/**
 * 
 * @export
 * @interface AnnouncementDto
 */
export interface AnnouncementDto {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    shortURL?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    category?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDto
     */
    priority?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDto
     */
    isActive?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof AnnouncementDto
     */
    dateCreated?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AnnouncementDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AnnouncementDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    webMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDto
     */
    showOnWeb?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    emailMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDto
     */
    sendEmail?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    smsMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementDto
     */
    sendSMS?: boolean | null;
    /**
     * 
     * @type {ClassSessionDto}
     * @memberof AnnouncementDto
     */
    classSession?: ClassSessionDto;
}

export function AnnouncementDtoFromJSON(json: any): AnnouncementDto {
    return AnnouncementDtoFromJSONTyped(json, false);
}

export function AnnouncementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnnouncementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'shortURL': !exists(json, 'shortURL') ? undefined : json['shortURL'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : (json['dateCreated'] === null ? null : new Date(json['dateCreated'])),
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'webMessage': !exists(json, 'webMessage') ? undefined : json['webMessage'],
        'showOnWeb': !exists(json, 'showOnWeb') ? undefined : json['showOnWeb'],
        'emailMessage': !exists(json, 'emailMessage') ? undefined : json['emailMessage'],
        'sendEmail': !exists(json, 'sendEmail') ? undefined : json['sendEmail'],
        'smsMessage': !exists(json, 'smsMessage') ? undefined : json['smsMessage'],
        'sendSMS': !exists(json, 'sendSMS') ? undefined : json['sendSMS'],
        'classSession': !exists(json, 'classSession') ? undefined : ClassSessionDtoFromJSON(json['classSession']),
    };
}

export function AnnouncementDtoToJSON(value?: AnnouncementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shortURL': value.shortURL,
        'title': value.title,
        'category': value.category,
        'priority': value.priority,
        'isActive': value.isActive,
        'dateCreated': value.dateCreated === undefined ? undefined : (value.dateCreated === null ? null : value.dateCreated.toISOString()),
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'webMessage': value.webMessage,
        'showOnWeb': value.showOnWeb,
        'emailMessage': value.emailMessage,
        'sendEmail': value.sendEmail,
        'smsMessage': value.smsMessage,
        'sendSMS': value.sendSMS,
        'classSession': ClassSessionDtoToJSON(value.classSession),
    };
}

