/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigitalPassport,
    DigitalPassportFromJSON,
    DigitalPassportFromJSONTyped,
    DigitalPassportToJSON,
} from './DigitalPassport';
import {
    Trustee,
    TrusteeFromJSON,
    TrusteeFromJSONTyped,
    TrusteeToJSON,
} from './Trustee';

/**
 * 
 * @export
 * @interface DigitalPassportTrusteeMapping
 */
export interface DigitalPassportTrusteeMapping {
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportTrusteeMapping
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportTrusteeMapping
     */
    trusteeId?: string | null;
    /**
     * 
     * @type {Trustee}
     * @memberof DigitalPassportTrusteeMapping
     */
    trustee?: Trustee;
    /**
     * 
     * @type {string}
     * @memberof DigitalPassportTrusteeMapping
     */
    digitalPassportId?: string | null;
    /**
     * 
     * @type {DigitalPassport}
     * @memberof DigitalPassportTrusteeMapping
     */
    digitalPassport?: DigitalPassport;
}

export function DigitalPassportTrusteeMappingFromJSON(json: any): DigitalPassportTrusteeMapping {
    return DigitalPassportTrusteeMappingFromJSONTyped(json, false);
}

export function DigitalPassportTrusteeMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigitalPassportTrusteeMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'trusteeId': !exists(json, 'trusteeId') ? undefined : json['trusteeId'],
        'trustee': !exists(json, 'trustee') ? undefined : TrusteeFromJSON(json['trustee']),
        'digitalPassportId': !exists(json, 'digitalPassportId') ? undefined : json['digitalPassportId'],
        'digitalPassport': !exists(json, 'digitalPassport') ? undefined : DigitalPassportFromJSON(json['digitalPassport']),
    };
}

export function DigitalPassportTrusteeMappingToJSON(value?: DigitalPassportTrusteeMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'trusteeId': value.trusteeId,
        'trustee': TrusteeToJSON(value.trustee),
        'digitalPassportId': value.digitalPassportId,
        'digitalPassport': DigitalPassportToJSON(value.digitalPassport),
    };
}

