/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DigitalPassportTrusteeMappingDto,
    DigitalPassportTrusteeMappingDtoFromJSON,
    DigitalPassportTrusteeMappingDtoFromJSONTyped,
    DigitalPassportTrusteeMappingDtoToJSON,
} from './DigitalPassportTrusteeMappingDto';
import {
    TrusteeStudentMappingDto,
    TrusteeStudentMappingDtoFromJSON,
    TrusteeStudentMappingDtoFromJSONTyped,
    TrusteeStudentMappingDtoToJSON,
} from './TrusteeStudentMappingDto';
import {
    TrusteeUserMappingDto,
    TrusteeUserMappingDtoFromJSON,
    TrusteeUserMappingDtoFromJSONTyped,
    TrusteeUserMappingDtoToJSON,
} from './TrusteeUserMappingDto';

/**
 * 
 * @export
 * @interface TrusteeDto
 */
export interface TrusteeDto {
    /**
     * 
     * @type {string}
     * @memberof TrusteeDto
     */
    id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrusteeDto
     */
    isActive?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TrusteeDto
     */
    type?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TrusteeDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrusteeDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrusteeDto
     */
    homePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrusteeDto
     */
    cellPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrusteeDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TrusteeDto
     */
    relationship?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrusteeDto
     */
    canPickup?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrusteeDto
     */
    receiveSMS?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrusteeDto
     */
    receiveEmail?: boolean | null;
    /**
     * 
     * @type {Array<DigitalPassportTrusteeMappingDto>}
     * @memberof TrusteeDto
     */
    digitalPassportTrusteeMappings?: Array<DigitalPassportTrusteeMappingDto> | null;
    /**
     * 
     * @type {Array<TrusteeUserMappingDto>}
     * @memberof TrusteeDto
     */
    trusteeUserMappings?: Array<TrusteeUserMappingDto> | null;
    /**
     * 
     * @type {Array<TrusteeStudentMappingDto>}
     * @memberof TrusteeDto
     */
    trusteeStudentMappings?: Array<TrusteeStudentMappingDto> | null;
}

export function TrusteeDtoFromJSON(json: any): TrusteeDto {
    return TrusteeDtoFromJSONTyped(json, false);
}

export function TrusteeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrusteeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'homePhone': !exists(json, 'homePhone') ? undefined : json['homePhone'],
        'cellPhone': !exists(json, 'cellPhone') ? undefined : json['cellPhone'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
        'canPickup': !exists(json, 'canPickup') ? undefined : json['canPickup'],
        'receiveSMS': !exists(json, 'receiveSMS') ? undefined : json['receiveSMS'],
        'receiveEmail': !exists(json, 'receiveEmail') ? undefined : json['receiveEmail'],
        'digitalPassportTrusteeMappings': !exists(json, 'digitalPassportTrusteeMappings') ? undefined : (json['digitalPassportTrusteeMappings'] === null ? null : (json['digitalPassportTrusteeMappings'] as Array<any>).map(DigitalPassportTrusteeMappingDtoFromJSON)),
        'trusteeUserMappings': !exists(json, 'trusteeUserMappings') ? undefined : (json['trusteeUserMappings'] === null ? null : (json['trusteeUserMappings'] as Array<any>).map(TrusteeUserMappingDtoFromJSON)),
        'trusteeStudentMappings': !exists(json, 'trusteeStudentMappings') ? undefined : (json['trusteeStudentMappings'] === null ? null : (json['trusteeStudentMappings'] as Array<any>).map(TrusteeStudentMappingDtoFromJSON)),
    };
}

export function TrusteeDtoToJSON(value?: TrusteeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isActive': value.isActive,
        'type': value.type,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'homePhone': value.homePhone,
        'cellPhone': value.cellPhone,
        'emailAddress': value.emailAddress,
        'relationship': value.relationship,
        'canPickup': value.canPickup,
        'receiveSMS': value.receiveSMS,
        'receiveEmail': value.receiveEmail,
        'digitalPassportTrusteeMappings': value.digitalPassportTrusteeMappings === undefined ? undefined : (value.digitalPassportTrusteeMappings === null ? null : (value.digitalPassportTrusteeMappings as Array<any>).map(DigitalPassportTrusteeMappingDtoToJSON)),
        'trusteeUserMappings': value.trusteeUserMappings === undefined ? undefined : (value.trusteeUserMappings === null ? null : (value.trusteeUserMappings as Array<any>).map(TrusteeUserMappingDtoToJSON)),
        'trusteeStudentMappings': value.trusteeStudentMappings === undefined ? undefined : (value.trusteeStudentMappings === null ? null : (value.trusteeStudentMappings as Array<any>).map(TrusteeStudentMappingDtoToJSON)),
    };
}

