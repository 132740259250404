import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { Link as Link2 } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const photos = [
  { src: "/static/images/homegrid/PhotoGrid_1.png" },
  { src: "/static/images/homegrid/PhotoGrid_2.png" },
  { src: "/static/images/homegrid/PhotoGrid_3.png" },
  { src: "/static/images/homegrid/PhotoGrid_4.png" },
  { src: "/static/images/homegrid/PhotoGrid_5.png" },
  { src: "/static/images/homegrid/PhotoGrid_6.png" },
  { src: "/static/images/homegrid/PhotoGrid_7.png" },
  { src: "/static/images/homegrid/PhotoGrid_8.png" },
  { src: "/static/images/homegrid/PhotoGrid_9.png" },
];

const PhotoGrid = () => {
  return (
    <Box>
      <Grid container spacing={{ xs: 5, sm: 5 }} justifyContent="center">
        {photos.map((photo, i) => (
          <Grid item xs={6} sm={4}>
            <Box>
              <Box
                justifyContent="center"
                component={LazyLoadImage}
                effect="blur"
                src={photo.src}
                alt={"Images"}
                width={"100%"}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PhotoGrid;
