/* tslint:disable */
/* eslint-disable */
/**
 * BloomRegistration.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppleDeviceRosterDto
 */
export interface AppleDeviceRosterDto {
    /**
     * 
     * @type {string}
     * @memberof AppleDeviceRosterDto
     */
    lastUpdated?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppleDeviceRosterDto
     */
    serialNumbers?: Array<string> | null;
}

export function AppleDeviceRosterDtoFromJSON(json: any): AppleDeviceRosterDto {
    return AppleDeviceRosterDtoFromJSONTyped(json, false);
}

export function AppleDeviceRosterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppleDeviceRosterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : json['lastUpdated'],
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
    };
}

export function AppleDeviceRosterDtoToJSON(value?: AppleDeviceRosterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastUpdated': value.lastUpdated,
        'serialNumbers': value.serialNumbers,
    };
}

