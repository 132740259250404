import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link as Link2 } from "react-router-dom";

const TestimonialPreview = () => {
  return (
    <Box
      bgcolor="#c5def1"
      sx={{
        paddingTop: { xs: 5, md: 10 },
        paddingBottom: { xs: 5, md: 10 },
        paddingLeft: { xs: 5, md: 20 },
        paddingRight: { xs: 5, md: 20 },
        marginTop: { xs: 1, md: 5 },
      }}
    >
      <Typography variant="h5" align="center" sx={{ fontWeight: 600, mb: 4 }}>
        “Everyday, my child came home with love in her heart and a smile on her
        face. She was treated so well and certainly blossomed into an excellent
        learner. [The team] provided an outstanding foundation and our entire
        family highly recommends this amazing learning center!“
      </Typography>
      <Typography variant="h5" align="center" sx={{ fontWdight: 200, mb: 4 }}>
        — Anna K.
      </Typography>
      <Box textAlign="center">
        <Button component={Link2} to="/testimonials" variant="blue">
          Read Our Testimonials
        </Button>
      </Box>
    </Box>
  );
};

export default TestimonialPreview;
